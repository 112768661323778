import { Box, CardContent, Card as MuiCard, IconButton, Stack, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import {
  EstimateStatus,
  IEstimate,
  IEstimateSplitProjection,
  IssueRole,
  PublishEstimatePayload,
  ReviewEstimateAction,
  ReviewEstimatePayload,
  SplitDocumentParty
} from 'shared/api/clients';
import { MuiIcon, RooIcon } from 'shared/icons';
import { ConfirmationModal, RooButton } from 'components';
import { rooFmt, showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { ActionsContainer } from 'pages/Workorders/ViewIssue/Documents/ActionsContainer';
import { ActionsMenu, ActionsMenuItem } from 'components/ActionsMenu';
import { EstimateInfo, EstimateInfoButton } from 'pages/Workorders/ViewIssue/Documents/Estimates/EstimateInfoButton';
import { EstimateRequestViewChange } from 'pages/Workorders/ViewIssue/Documents/Estimates/types';
import { useIsGeneralContractor } from '../../../../../shared/store';
import { Disclosure, useDisclosure } from '@roo/lib';
import { orderBy } from 'lodash';
import { EnumMapEstimateStatus } from '../../../../../shared/store/enums/estimateStatus';
import { SplitEstimateModal } from './SplitEstimateModal';

export const EstimateList = ({
  requestViewChange,
  canCreate,
  canEdit
}: {
  requestViewChange: EstimateRequestViewChange;
  canCreate: boolean;
  canEdit: boolean;
}) => {
  const { userRole, estimates } = useCurrentIssue();
  const isGc = useIsGeneralContractor();
  let targetEstimates = estimates.filter(
    (x) =>
      (x.status !== EstimateStatus.Draft || userRole === IssueRole.Vendor || userRole === IssueRole.Tech) &&
      (x.status !== EstimateStatus.Edited || userRole === IssueRole.Vendor || userRole === IssueRole.Tech || isGc) &&
      (x.status !== EstimateStatus.Canceled || userRole === IssueRole.Vendor || userRole === IssueRole.Tech || isGc)
  );

  const splitEstimateModal = useDisclosure(false);

  if (targetEstimates.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ p: 4 }}>
          <p>There are no Estimates</p>
          {canCreate && (
            <div className={'text-center'}>
              <RooButton color={'primary'} icon={'plus'} onClick={() => requestViewChange({ view: 'confirm' })}>
                Create one
              </RooButton>
            </div>
          )}
        </Box>
      </Box>
    );
  }

  const sorted = orderBy(targetEstimates, (x) => x.friendlyId, 'desc');

  return (
    <Stack gap={3}>
      {sorted.map((x) => (
        <MuiCard variant={'outlined'} sx={{ width: '100%' }} key={x.id}>
          <CardContent sx={{ width: '100%' }}>
            <Stack sx={{ width: '100%' }} direction={'row'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
              <Stack sx={{ width: '100%' }} direction={'row'} gap={2} flex={1} alignItems={'center'} flexWrap={'wrap'}>
                <Stack>
                  {x.pdfUrls == null && x.friendlyId}
                  {x.pdfUrls != null && (
                    <a href={x.pdfUrls.downloadUrl} rel="noreferrer">
                      <RooIcon icon={'download'} /> {x.friendlyId}
                    </a>
                  )}
                  <div className={'mb-0'}>
                    <Badge bg={EnumMapEstimateStatus.get(x.status).color}>
                      {EnumMapEstimateStatus.get(x.status).display}
                    </Badge>
                  </div>
                </Stack>
                <Stack sx={{ minWidth: 85 }}>
                  <Typography variant={'caption'}>Date</Typography>
                  <Typography>{rooFmt.instantDateMaybe(x.sortDate, '-')}</Typography>
                </Stack>

                <Stack sx={{ minWidth: 80 }}>
                  <Typography variant={'caption'}>Subtotal</Typography>
                  <Typography>{rooFmt.moneyMaybe(x.totals.subtotal, 'N/A')}</Typography>
                </Stack>
                <Stack sx={{ minWidth: 80 }}>
                  <Typography variant={'caption'}>Tax</Typography>{' '}
                  <Typography>{rooFmt.moneyMaybe(x.totals.tax, 'N/A')}</Typography>
                </Stack>
                <Stack sx={{ minWidth: 80 }}>
                  <Typography variant={'caption'}>Grand Total</Typography>
                  <Typography>{rooFmt.moneyMaybe(x.totals.finalTotal, 'N/A')}</Typography>
                </Stack>
              </Stack>

              <Box>
                <ActionsContainer>
                  <EstimateMenu canEdit={canEdit} estimate={x} requestViewChange={requestViewChange} />
                  <EstimateInfoButton estimate={x} />
                </ActionsContainer>
              </Box>
            </Stack>
            {(userRole === IssueRole.MainPropertyManager || userRole === IssueRole.AltPropertyManager) &&
              x.split != null && (
                <Stack p={2} gap={1}>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography fontWeight={600} fontSize={'large'}>
                      Split
                    </Typography>
                    <IconButton onClick={splitEstimateModal.open} size={'small'} color={'primary'}>
                      <MuiIcon.Edit fontSize={'small'} />
                    </IconButton>
                  </Stack>
                  <Stack direction={'row'} gap={2}>
                    {x.split.ownerProjection != null && <SplitEstimateCard projection={x.split.ownerProjection} />}
                    {x.split.tenantProjection != null && <SplitEstimateCard projection={x.split.tenantProjection} />}
                  </Stack>
                  <SplitEstimateModal estimateId={x.id} modal={splitEstimateModal} />
                </Stack>
              )}
          </CardContent>
        </MuiCard>
      ))}
    </Stack>
  );
};

export const EstimateListStandalone = () => {
  return (
    <Card>
      <Card.Header>Estimates</Card.Header>
      <Card.Body>{<EstimateList canEdit={false} requestViewChange={null} canCreate={false} />}</Card.Body>
    </Card>
  );
};

const EstimateMenu = ({
  estimate,
  requestViewChange,
  canEdit
}: {
  canEdit: boolean;
  estimate: IEstimate;
  requestViewChange: EstimateRequestViewChange;
}) => {
  const actions = useAllowedActions(estimate, canEdit);

  const acceptModal = useDisclosure(false);
  const forceAcceptModal = useDisclosure(false);
  const rejectModal = useDisclosure(false);
  const publishModal = useDisclosure(false);
  const splitEstimateModal = useDisclosure(false);

  if (actions.length === 0) {
    return null;
  }

  return (
    <>
      <ActionsMenu moreButton={(onClick) => <RooButton icon={'ellipsis-h'} onClick={onClick} />}>
        <ActionsMenuItem
          show={actions.includes('edit')}
          onClick={() => {
            requestViewChange({ view: 'edit', estimate: estimate });
          }}
          Icon={MuiIcon.Edit}
          color={'primary'}
        >
          Edit
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('publish')}
          onClick={publishModal.open}
          Icon={MuiIcon.Publish}
          color={'primary'}
        >
          Send to PM
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('accept')}
          onClick={acceptModal.open}
          Icon={MuiIcon.CheckCircle}
          color={'success'}
        >
          Accept
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('forceAccept')}
          onClick={forceAcceptModal.open}
          Icon={MuiIcon.Hardware}
          color={'warning'}
        >
          Force Accept
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('reject')}
          onClick={rejectModal.open}
          Icon={MuiIcon.Cancel}
          color={'error'}
        >
          Reject
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('split')}
          onClick={splitEstimateModal.open}
          Icon={MuiIcon.CallSplit}
          color={'primary'}
        >
          Split
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('updateSplit')}
          onClick={splitEstimateModal.open}
          Icon={MuiIcon.CallSplit}
          color={'primary'}
        >
          Update Split
        </ActionsMenuItem>
      </ActionsMenu>
      <ReviewEstimateModal modal={acceptModal} estimate={estimate} action={ReviewEstimateAction.Accept} />
      <ReviewEstimateModal modal={forceAcceptModal} estimate={estimate} action={ReviewEstimateAction.ForceAccept} />
      <ReviewEstimateModal modal={rejectModal} estimate={estimate} action={ReviewEstimateAction.Reject} />
      <PublishEstimateModal modal={publishModal} estimate={estimate} />
      <SplitEstimateModal estimateId={estimate.id} modal={splitEstimateModal} />
    </>
  );
};

type EstimateAction = 'edit' | 'publish' | 'accept' | 'reject' | 'forceAccept' | 'split' | 'updateSplit';

const useAllowedActions = (estimate: IEstimate, canEdit: boolean) => {
  const { userRole, parentIssue } = useCurrentIssue();
  const actions: EstimateAction[] = [];
  const status = estimate.status;

  if (canEdit && userRole === IssueRole.Vendor && status === EstimateStatus.Draft) {
    actions.push('publish');
  }

  if (canEdit && parentIssue == null && userRole === IssueRole.Vendor && status === EstimateStatus.PendingReview) {
    actions.push('forceAccept');
  }

  if (
    canEdit &&
    userRole === IssueRole.Vendor &&
    (status === EstimateStatus.Draft || status === EstimateStatus.PendingReview)
  ) {
    actions.push('edit');
  }

  const isManager = userRole === IssueRole.MainPropertyManager || userRole === IssueRole.AltPropertyManager;
  if (canEdit && isManager && status === EstimateStatus.PendingReview) {
    actions.push('accept');
    actions.push('reject');
  }

  if (isManager && status !== EstimateStatus.Draft && estimate.split == null) {
    actions.push('split');
  }

  if (isManager && status !== EstimateStatus.Draft && estimate.split != null) {
    actions.push('updateSplit');
  }

  return actions;
};

const ReviewEstimateModal = ({
  estimate,
  action,
  modal
}: {
  action: ReviewEstimateAction;
  estimate: IEstimate;
  modal: Disclosure;
}) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [saving, setSaving] = useState(false);
  const [notes, setNotes] = useState<string>('');

  const save = async () => {
    try {
      setSaving(true);
      const updated = await apiProvider.issues.estimates.reviewEstimate(
        new ReviewEstimatePayload({
          action: action,
          estimateId: estimate.id,
          issueId: issue.id,
          notes: notes.trim() === '' ? null : notes
        })
      );

      onIssueUpdate(updated);
      showSuccess();
      modal.close();
      setSaving(false);
    } catch (e) {
      setSaving(false);
    }
  };

  return (
    <>
      <ConfirmationModal
        size={'xl'}
        question={
          <>
            <p className={'mb-4'}>
              {action === ReviewEstimateAction.Accept
                ? 'Do you want to accept this estimate?'
                : action === ReviewEstimateAction.ForceAccept
                ? 'Do you want to accept this estimate without waiting for PM approval?'
                : 'Do you want to reject this estimate?'}
            </p>
            <div className={'mt-4 form-group'}>
              <label htmlFor={'review-notes'}>Notes</label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className={'form-control'}
                id={'review-notes'}
                rows={3}
                placeholder={'Your comments'}
              />
            </div>

            <EstimateInfo estimate={estimate} />
          </>
        }
        visible={modal.isOpen}
        onClose={modal.close}
        onSave={() => void save()}
        running={saving}
      />
    </>
  );
};

const SplitEstimateCard = ({ projection }: { projection: IEstimateSplitProjection }) => {
  return (
    <MuiCard variant={'outlined'} sx={{ maxWidth: 300, flex: 1 }}>
      <CardContent>
        <Stack direction={'column'} gap={2}>
          <Stack gap={1}>
            <Typography fontSize={'large'} fontWeight={600}>
              {projection.friendlyId}
            </Typography>
            <Stack direction={'row'} gap={2}>
              <Stack>
                <Typography variant={'caption'}>Party</Typography>
                <Typography>{projection.party === SplitDocumentParty.Tenant ? 'Tenant' : 'Owner'}</Typography>
              </Stack>
              <Stack>
                <Typography variant={'caption'}>Amount</Typography>
                <Typography>{rooFmt.money(projection.amount)}</Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={1}>
            <Typography variant={'caption'}>PDF</Typography>
            <Stack direction={'row'} gap={1}>
              <Button
                size={'small'}
                href={`/api/issues/estimates/render-split-pdf?splitId=${projection.splitId}&party=${projection.party}`}
                target={'_blank'}
                startIcon={<MuiIcon.PictureAsPdf />}
              >
                View
              </Button>
              <Button
                size={'small'}
                href={`/api/issues/estimates/download-split-pdf?splitId=${projection.splitId}&party=${projection.party}`}
                target={'_blank'}
                startIcon={<MuiIcon.Download />}
              >
                Download
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </MuiCard>
  );
};

export const PublishEstimateModal = ({ estimate, modal }: { estimate: IEstimate; modal: Disclosure }) => {
  const [saving, setSaving] = useState(false);
  const { issue, onIssueUpdate } = useCurrentIssue();

  const save = async () => {
    try {
      setSaving(true);
      const updated = await apiProvider.issues.estimates.publishEstimate(
        new PublishEstimatePayload({
          estimateId: estimate.id,
          issueId: issue.id
        })
      );

      onIssueUpdate(updated);
      showSuccess();
      modal.close();
      setSaving(false);
    } catch (e) {
      setSaving(false);
    }
  };

  return (
    <>
      <ConfirmationModal
        question={'Do you want to send this estimate to the PM?'}
        visible={modal.isOpen}
        onClose={modal.close}
        onSave={() => void save()}
        running={saving}
      />
    </>
  );
};
