import React, { InputHTMLAttributes } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { fieldClassNames, stringTransform } from 'components/form/utils';
import { ErrorMessage } from 'components/form/ErrorMessage';
import { ControlProps } from 'components/form/types';
import { FormGroup } from 'react-bootstrap';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { RooFormLabel } from './RooFormLabel';

type BaseProps = Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'id' | 'name' | 'onChange' | 'value' | 'onBlur'>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> &
  BaseProps & {
    groupProps?: FormGroupProps;
  };
export type FieldTextAreaProps<TForm extends FieldValues> = FinalProps<TForm>;

export const FieldTextArea = <TForm extends FieldValues>({
  name,
  control,
  label,
  className,
  groupProps,
  required,
  ...rest
}: FinalProps<TForm>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });
  return (
    <FormGroup {...(groupProps ?? {})}>
      <RooFormLabel label={label} name={name} required={required} />
      <textarea
        className={fieldClassNames(className, error)}
        name={name}
        id={name}
        onChange={(e) => field.onChange(stringTransform.output(e))}
        value={stringTransform.input(field.value)}
        onBlur={field.onBlur}
        {...rest}
      />
      <ErrorMessage error={error} />
    </FormGroup>
  );
};
