import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import {
  CreatePersonModel,
  CreatePropertyPayload,
  CreateTenantModel,
  DuplicateProperty,
  ModifyAddressPayload
} from 'shared/api/clients';
import { useRooForm } from 'components/form/utils';
import { redirect, Routes } from 'shared/routing';
import { useAppStore, useCurrentUser } from 'shared/store';
import { ContentWrapper, NavSlim, RooButton, RooDialog, RouterLink } from 'components';
import { rooDate, showSuccess } from 'shared/utils';
import { FormDefinition, FormSchema } from 'pages/Properties/forms';
import { PropertyForm } from 'pages/Properties/PropertyForm';
import React, { useMemo, useState } from 'react';
import { Alert, Stack } from '@mui/material';
import { Center } from '../../components/Center';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { Disclosure, useDisclosure } from '@roo/lib';

export const PropertyCreate = () => {
  const { handleSubmit, formState, control, setValue, watch } = useRooForm(FormSchema, {
    defaultValues: {
      hasPool: false,
      isOccupied: false,
      tenants: []
    }
  });
  const [duplicates, setDuplicates] = useState<DuplicateProperty[]>(null);
  const duplicateDisclosure = useDisclosure(false);

  const user = useCurrentUser();
  const onPropertyCreated = useAppStore((x) => x.actions.onPropertyCreated);

  const save = async (values: FormDefinition) => {
    setDuplicates(null);
    try {
      const saveResult = await apiProvider.propertiesClient.create(
        new CreatePropertyPayload({
          name: values.name,
          maintenanceLimit: values.maintenanceLimit,
          isOccupied: values.isOccupied,
          address: new ModifyAddressPayload({
            address1: values.address.address1,
            address2: values.address.address2,
            city: values.address.city,
            state: values.address.state,
            zipCode: values.address.zipCode
          }),
          owner:
            values.owner == null
              ? null
              : new CreatePersonModel({
                  email: values.owner.email,
                  phone: values.owner.phone,
                  firstName: values.owner.firstName,
                  lastName: values.owner.lastName,
                  userId: null
                }),
          bathroomNumber: values.bathroomNo,
          bedroomNumber: values.bedroomNo,
          gateCode: values.gateCode,
          hasPool: values.hasPool,
          lotSize: values.lotSize,
          propertyArea: values.propertyArea,
          managementCompanyId: user.managementCompany.id,
          managerId: values.managerId,
          tenants: values.tenants.map(
            (x) =>
              new CreateTenantModel({
                firstName: x.firstName,
                lastName: x.lastName,
                phone: x.phone,
                email: x.email,
                moveInDate: rooDate.makeInstant(x.moveInDate),
                moveOutDate: rooDate.makeInstant(x.moveOutDate),
                userId: null
              })
          )
        })
      );

      if (saveResult.success) {
        onPropertyCreated(saveResult.property);
        redirect({ pathname: Routes.PropertyView, params: { id: saveResult.property.id } });
        showSuccess();
      } else {
        setDuplicates(saveResult.duplicates);
        duplicateDisclosure.open();
      }
    } catch (err) {}
  };

  return (
    <>
      <Helmet>
        <title>Create Property - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Property List', destination: Routes.PropertyList }, { text: 'Create' }]} />
      <ContentWrapper>
        <Form onSubmit={handleSubmit(save)}>
          <PropertyForm
            returnUrl={{ pathname: Routes.PropertyList }}
            control={control}
            formState={formState}
            isEditing={false}
            setValue={setValue}
            watch={watch}
          />
        </Form>
        <DuplicatesModal disclosure={duplicateDisclosure} duplicates={duplicates} />
      </ContentWrapper>
    </>
  );
};

const DuplicatesModal = ({ disclosure, duplicates }: { disclosure: Disclosure; duplicates: DuplicateProperty[] }) => {
  const columns = useMemo<MRT_ColumnDef<DuplicateProperty>[]>(
    () => [
      {
        header: 'Address1',
        accessorKey: 'address.address1',
        Cell: ({ row }) => (
          <RouterLink target={'_blank'} to={{ pathname: Routes.PropertyView, params: { id: row.original.propertyId } }}>
            {row.original.address.address1}
          </RouterLink>
        )
      },
      { header: 'Address2', accessorKey: 'address.address2' },
      { header: 'City', accessorKey: 'address.city' },
      { header: 'State', accessorKey: 'address.state' },
      { header: 'ZIP', accessorKey: 'address.zipCode' }
    ],
    []
  );
  return (
    <RooDialog maxWidth={'lg'} fullWidth open={disclosure.isOpen} onClose={disclosure.close}>
      <RooDialog.Title onClose={disclosure.close}>Existing Properties</RooDialog.Title>
      <RooDialog.Content>
        <Stack spacing={2}>
          <Center>
            <Alert color={'error'}>One or more properties with this address already exist.</Alert>
          </Center>
          <MaterialReactTable
            columns={columns}
            enablePagination={false}
            data={duplicates ?? []}
            state={{ density: 'compact' }}
            enableGlobalFilter={false}
            enableHiding={false}
            enableColumnDragging={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableTopToolbar={false}
          />
        </Stack>
      </RooDialog.Content>
      <RooDialog.Actions>
        <RooDialog.Actions>
          <RooButton variant={'primary'} onClick={disclosure.close}>
            Close
          </RooButton>
        </RooDialog.Actions>
      </RooDialog.Actions>
    </RooDialog>
  );
};
