import React, { useState } from 'react';
import { FieldMuiSwitch, FieldText, useRooForm } from '../form';
import { PersonSchema, showSuccess } from '../../shared/utils';
import { AddClientContactPayload } from '../../shared/api/clients';
import { apiProvider } from '../../shared/api/apiProvider';
import { RooDialog } from '../RooDialog';
import { Alert, Form, FormGroup } from 'react-bootstrap';
import { SaveButtonMui } from '../SaveButton';
import Button from '@mui/material/Button';
import { z } from 'zod';
import { Autocomplete, TextField } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Disclosure } from '@roo/lib';

export const AddContactModal = ({ disclosure, companyId }: { disclosure: Disclosure; companyId: string }) => {
  return (
    <RooDialog open={disclosure.isOpen} onClose={disclosure.close}>
      <RooDialog.Title onClose={disclosure.close}>Add Contact</RooDialog.Title>
      <AddContactBody disclosure={disclosure} companyId={companyId} />
    </RooDialog>
  );
};

const FormSchema = z.object({
  person: PersonSchema,
  muteNotifications: z.boolean(),
  designation: z.string().nullish()
});

type FormDefinition = z.infer<typeof FormSchema>;

const AddContactBody = ({ disclosure, companyId }: { disclosure: Disclosure; companyId: string }) => {
  const [error, setError] = useState(null);
  const qc = useQueryClient();
  const { handleSubmit, formState, control, reset } = useRooForm(FormSchema, {
    defaultValues: {
      muteNotifications: true
    }
  });

  const { mutateAsync } = useMutation({
    mutationFn: (values: FormDefinition) =>
      apiProvider.vendorClient.addClientContact(
        new AddClientContactPayload({
          email: values.person.email,
          companyId: companyId,
          firstName: values.person.firstName,
          lastName: values.person.lastName,
          phone: values.person.phone,
          designation: values.designation,
          muteNotifications: values.muteNotifications
        })
      ),
    onSuccess: async (result) => {
      if (result.success) {
        await qc.invalidateQueries(['contacts', companyId]);
        void qc.invalidateQueries(['vendor', 'all-designations']);
        setError(null);
        reset();
        disclosure.close();
        showSuccess(`✨ The contact has been added.`);
      } else {
        setError('A user with that e-mail already exists');
      }
    }
  });

  return (
    <Form noValidate style={{ marginBottom: 'initial' }} onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
      <RooDialog.Content>
        <Grid2 container spacing={2}>
          <Grid2 xs={12} xl={6}>
            <FieldText
              control={control}
              name={'person.firstName'}
              required
              label={'First Name'}
              placeholder={'First Name'}
              className="Input-field"
            />
          </Grid2>
          <Grid2 xs={12} xl={6}>
            <FieldText
              control={control}
              name={'person.lastName'}
              required
              label={'Last Name'}
              placeholder={'Last Name'}
              className="Input-field"
            />
          </Grid2>
          <Grid2 xs={12} xl={6}>
            <FieldText
              control={control}
              name={'person.email'}
              required
              label={'Email'}
              placeholder={'Email'}
              className="Input-field"
            />
          </Grid2>
          <Grid2 xs={12} xl={6}>
            <FieldText
              control={control}
              name={'person.phone'}
              required
              label={'Phone'}
              placeholder={'Phone'}
              className="Input-field"
            />
          </Grid2>
          <Grid2 xs={12} xl={6}>
            <FormGroup>
              <DesignationField control={control} />
            </FormGroup>
          </Grid2>
          <Grid2 xs={12} xl={6}>
            <h6>Mute Notifications</h6>
            <FieldMuiSwitch control={control} name={'muteNotifications'} label={'Mute Notifications'} />
          </Grid2>
        </Grid2>
        {error && <Alert variant={'danger'}>{error}</Alert>}
      </RooDialog.Content>
      <RooDialog.Actions>
        <SaveButtonMui control={control}>Save</SaveButtonMui>
        &nbsp;&nbsp;
        <Button
          color="muted"
          disabled={formState.isSubmitting || formState.isValidating}
          size="small"
          onClick={disclosure.close}
        >
          Cancel
        </Button>
      </RooDialog.Actions>
    </Form>
  );
};

const DesignationField = ({ control }: { control: Control<FormDefinition> }) => {
  const { isLoading, data } = useQuery(['vendor', 'all-designations'], () =>
    apiProvider.vendorClient.getAvailableDesignations()
  );

  const { field } = useController({
    control: control,
    name: 'designation'
  });

  return (
    <Autocomplete
      loading={isLoading}
      options={data ?? []}
      value={field.value}
      onInputChange={(_, val) => {
        field.onChange(val);
      }}
      freeSolo
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label="Designation" />}
    />
  );
};
