import { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { apiProvider } from 'shared/api/apiProvider';
import {
  AttachmentEntityType,
  CapabilityType,
  FileVisibility,
  Issue,
  IWorkorder,
  NoteEntityType,
  RooPermission
} from 'shared/api/clients';
import { FileList } from 'components/Files/FileList';
import { IssuesClientGrid } from 'components/Issues/IssuesClientGrid';
import { RooIcon } from 'shared/icons';
import { redirect, Routes } from 'shared/routing';
import { rooEnum, useCompanyContext, useHasCapability, useHasPermission, useIsGeneralContractor } from 'shared/store';
import {
  LoaderBig,
  LoaderSmall,
  NavSlim,
  PageContentSection,
  PropertyCard,
  UserLink,
  WithPlaceholder
} from 'components';
import { rooFmt } from 'shared/utils';
import { AddEditIssueModal } from 'pages/Workorders/AddEditIssueModal';
import { EditWorkorderModal } from 'pages/Workorders/EditWorkorderModal';
import { LegacyNotice } from '../shared/LegacyNotice';
import { WorkorderActivityLog } from 'pages/Workorders/ViewWorkorder/WorkorderActivityLog';
import { InternalNotesCard } from '../shared/InternalNotesCard';

type ViewWorkorderState = {
  workorder: IWorkorder;
  isLoading: boolean;
};
export const ViewWorkorder = () => {
  const [{ workorder, isLoading }, setState] = useState<ViewWorkorderState>({
    workorder: null,
    isLoading: true
  });
  const takeActionWorkorder = useHasPermission(RooPermission.TakeActionWorkorder, workorder?.managementCompany.id);
  const isManager = useHasCapability(CapabilityType.PropertyManager, workorder?.managementCompany.id);
  const isGc = useIsGeneralContractor();
  const [editIsVisible, setEditIsVisible] = useState(false);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    const load = async () => {
      try {
        const wo = await apiProvider.workOrdersClient.getById(id);
        setState({ workorder: wo, isLoading: false });
      } catch (err) {
        setState({ workorder: null, isLoading: false });
      }
    };

    void load();
  }, [id]);

  if (isLoading) {
    return <LoaderBig />;
  }

  if (!isLoading && workorder == null) {
    throw new Error('No workorder loaded');
  }

  return (
    <>
      <Helmet>
        <title>{workorder.friendlyId} - Workorder Details - Walkthroo</title>
      </Helmet>
      <NavSlim
        breadcrumbs={[
          { text: workorder.friendlyId, destination: { pathname: Routes.WorkorderView, params: { id: workorder.id } } }
        ]}
      />
      <PageContentSection>
        <Row>
          <Col xl={3} className={'mb-4'}>
            <Card>
              <Card.Header>
                <strong>Workorder</strong>
                {takeActionWorkorder && (
                  <button onClick={() => setEditIsVisible(true)} className={'btn btn-primary btn-sm float-end slim'}>
                    <RooIcon icon={['fas', 'pencil-alt']} /> Edit
                  </button>
                )}
              </Card.Header>
              <Card.Body>
                <h4 className={'mb-0'}>{workorder.friendlyId}</h4>
                <div className={'d-flex'}>
                  <p className={'mb-0'} style={{ marginRight: '5px' }}>
                    <Badge bg={rooEnum.priority.color(workorder.priority)}>{workorder.priorityDisplay}</Badge>
                  </p>
                  <p className={'mb-0'}>
                    <Badge bg={rooEnum.workorderStatus.color(workorder.status)}>
                      {rooEnum.workorderStatus.display(workorder.status)}
                    </Badge>
                  </p>
                </div>

                <div className={'mt-4'}>
                  <strong>Property</strong>
                </div>

                <PropertyCard className={'mb-2'} property={workorder.property} showZillow={true} />
                <dl>
                  <dt>Company</dt>
                  <dd>{workorder.managementCompany.name}</dd>
                  <dt>Ref#</dt>
                  <dd>
                    <WithPlaceholder value={workorder.externalReference} />
                  </dd>
                  <dt>Gate Code</dt>
                  <dd>
                    <WithPlaceholder value={workorder.gateCode} />
                  </dd>
                  <dt>Lock Box</dt>
                  <dd>
                    <WithPlaceholder value={workorder.lockBox} />
                  </dd>
                  <dt>Notes</dt>
                  <dd>
                    <WithPlaceholder value={workorder.notes} />
                  </dd>
                  {isManager && (
                    <>
                      <dt>Created</dt>
                      <dd>
                        {rooFmt.date(workorder.dateCreated)} by <UserLink user={workorder.createdBy} role={null} />
                      </dd>
                    </>
                  )}
                </dl>
                {/*<strong>Key Contacts</strong>*/}
                {/*<WorkorderContacts workorder={workorder} />*/}
              </Card.Body>
            </Card>
          </Col>
          <Col xl={9}>
            <Row>
              <Col xl={12} className={'mb-4'}>
                <LegacyNotice item={workorder} type={'workorder'} />
              </Col>
              <Col xl={12} className={'mb-4'}>
                <WorkorderNotice workorder={workorder} />
              </Col>
              <Col xl={12} className={'mb-4'}>
                <IssuesSection workorder={workorder} />
              </Col>
              <NotesSection workorder={workorder} />
              <Col xl={12} className={'mb-4'}>
                <FileList
                  entityType={AttachmentEntityType.Workorder}
                  entityId={workorder.id}
                  allowUpload={isManager || isGc}
                  forceAllowDelete={isManager || isGc}
                  visibility={FileVisibility.Unknown}
                />
              </Col>
              {isManager && (
                <Col xl={12}>
                  <WorkorderActivityLog workorder={workorder} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </PageContentSection>
      <EditWorkorderModal
        workorder={workorder}
        isVisible={editIsVisible}
        onCloseRequest={() => setEditIsVisible(false)}
        onSave={(wo) => {
          setState((x) => ({ ...x, workorder: wo }));
        }}
      />
    </>
  );
};

const WorkorderNotice = ({ workorder }: { workorder: IWorkorder }) => {
  const isGc = useIsGeneralContractor();
  if (!isGc) {
    return null;
  }

  return (
    workorder.managementCompany.issueNotice && (
      <Alert variant={'warning'}>
        <Alert.Heading>PM Details</Alert.Heading>
        <p>
          <WithPlaceholder value={workorder.managementCompany.issueNotice} />
        </p>
      </Alert>
    )
  );
};

const NotesSection = ({ workorder }: { workorder: IWorkorder }) => {
  const companyCtx = useCompanyContext();
  if (companyCtx == null) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'}>
      <InternalNotesCard entityType={NoteEntityType.Workorder} entityId={workorder.id} />
    </Col>
  );
};

const IssuesSection = ({ workorder }: { workorder: IWorkorder }) => {
  const [{ isLoading, issues }, setState] = useState<{ isLoading: boolean; issues: Issue[] }>({
    isLoading: true,
    issues: null
  });
  useEffect(() => {
    const load = async () => {
      try {
        const issues = await apiProvider.issues.main.listForWorkorder(workorder.id);
        setState({ issues: issues, isLoading: false });
      } catch (err) {
        setState({ issues: null, isLoading: false });
      }
    };

    void load();
  }, [workorder.id]);

  if (isLoading) {
    return <LoaderSmall />;
  }

  return (
    <Card>
      <Card.Header>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>Issues</div>
          <AddIssueButton workorder={workorder} />
        </div>
      </Card.Header>
      <Card.Body>{issues && <IssuesClientGrid issues={issues} />}</Card.Body>
    </Card>
  );
};

const AddIssueButton = ({ workorder }: { workorder: IWorkorder }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const openModal = () => setIsModalVisible(true);

  const takeActionWorkorder = useHasPermission(RooPermission.TakeActionWorkorder, workorder.managementCompany.id);
  if (!takeActionWorkorder) {
    return null;
  }

  return (
    <>
      <Button variant={'primary'} onClick={openModal}>
        + Add Issue
      </Button>
      <AddEditIssueModal
        title={'New Issue'}
        workorderId={workorder.id}
        managementCompanyId={workorder.managementCompany.id}
        propertyId={workorder.propertyId}
        visible={isModalVisible}
        onCloseRequest={closeModal}
        onIssueSaved={(newIssue) => {
          redirect({ pathname: Routes.IssueView, params: { workorderId: workorder.id, issueId: newIssue.issue.id } });
        }}
      />
    </>
  );
};
