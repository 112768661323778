import { UserAccount } from '../../../shared/api/clients';
import React, { useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { RooDialog } from '../../../components';
import { rooFmt } from '../../../shared/utils';
import { rooEnum } from '../../../shared/store';

export const CompanyInfoMoreButton = ({ row }: { row: UserAccount }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} size={'small'} variant={'text'}>
        Show {row.companies.length - 1} more
      </Button>
      <CompanyInfoModal row={row} open={open} onClose={() => setOpen(false)} />
    </>
  );
};

const CompanyInfoModal = ({ row, open, onClose }: { row: UserAccount; open: boolean; onClose: () => void }) => {
  return (
    <RooDialog maxWidth={'md'} fullWidth open={open} onClose={onClose}>
      <RooDialog.Title>Company Memberships for {rooFmt.fullName(row)}</RooDialog.Title>
      <RooDialog.Content>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell align="right">Capabilities</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.companies.map((company) => (
                <TableRow key={company.managementCompanyId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {company.companyName}
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={'small'} color={'gray.400'}>
                      {company.capabilities.map((x) => rooEnum.capabilityType.display(x)).join(', ')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </RooDialog.Content>
    </RooDialog>
  );
};
