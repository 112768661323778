import { Helmet } from 'react-helmet';
import { RooPermission } from 'shared/api/clients';
import { useHasPermission } from 'shared/store';
import { WithReqChildren } from 'shared/utils';
import { MessageBig } from 'components/MessageBig';

export const PermissionCheckPage = ({ children, permission }: WithReqChildren & { permission: RooPermission }) => {
  const canView = useHasPermission(permission);
  if (canView) {
    return <>{children}</>;
  }

  return (
    <>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      <div className={'container py-4'}>
        <MessageBig message="Couldn't find what you're looking for" />
      </div>
    </>
  );
};
