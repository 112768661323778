import { Button, Card, CardContent, CardHeader } from '@mui/material';
import * as React from 'react';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { CapabilityType, DescribedDashboardStat } from 'shared/api/clients';
import { useWorkordersGrid, WorkordersGrid } from 'components/Issues/WorkordersGrid';
import { Routes } from 'shared/routing';
import { RooButton, RouterLink, WhenCapable } from 'components';
import { MuiIcon } from '../../shared/icons';
import { DashboardContext } from './utils';

export const ConditionalWorkordersArea = ({
  companyId,
  stat,
  vendorIds,
  stateCode,
  city,
  accountManagerId
}: {
  companyId: string;
  stat: DescribedDashboardStat;
  vendorIds: string[];
  stateCode: string;
  city: string;
  accountManagerId: string;
}) => {
  return (
    <>
      {stat == null && (
        <WorkordersArea
          companyId={companyId}
          vendorIds={vendorIds}
          stateCode={stateCode}
          city={city}
          accountManagerId={accountManagerId}
        />
      )}
      {stat != null && (
        <FilteredWorkordersArea
          companyId={companyId}
          stat={stat}
          vendorIds={vendorIds}
          stateCode={stateCode}
          city={city}
          accountManagerId={accountManagerId}
        />
      )}
    </>
  );
};

export const WorkordersArea = ({
  companyId,
  vendorIds,
  stateCode,
  city,
  accountManagerId
}: {
  companyId: string;
  vendorIds: string[];
  stateCode: string;
  city: string;
  accountManagerId: string;
}) => {
  const activeWoGrid = useWorkordersGrid({
    forceExpanded: false,
    externalFilters: {
      forActive: true,
      forCompanyId: companyId,
      forVendors: vendorIds,
      forCity: city,
      forState: stateCode,
      forAccountManager: accountManagerId
    }
  });
  const inactiveWoGrid = useWorkordersGrid({
    forceExpanded: false,
    externalFilters: {
      forActive: false,
      forCompanyId: companyId,
      forVendors: vendorIds,
      forCity: city,
      forState: stateCode,
      forAccountManager: accountManagerId
    }
  });

  const activeRefresh = activeWoGrid.refresh;
  const inactiveRefresh = inactiveWoGrid.refresh;

  useEffect(() => {
    void activeRefresh();
    void inactiveRefresh();
  }, [activeRefresh, inactiveRefresh, companyId, vendorIds, city, stateCode, accountManagerId]);

  return (
    <>
      <Card>
        <CardHeader
          title={'Active Workorders'}
          action={
            <>
              <WhenCapable capability={CapabilityType.PropertyManager}>
                <Button
                  size={'large'}
                  color="primary"
                  startIcon={<MuiIcon.Add />}
                  component={RouterLink}
                  to={{ pathname: Routes.WorkorderCreate }}
                >
                  Add Workorder
                </Button>
              </WhenCapable>
            </>
          }
        />
        <CardContent>
          <WorkordersGrid state={activeWoGrid} />
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={'Closed Workorders (last 7d)'} />
        <CardContent>
          <WorkordersGrid state={inactiveWoGrid} />
        </CardContent>
      </Card>
    </>
  );
};

export const FilteredWorkordersArea = ({
  stat,
  companyId,
  vendorIds,
  stateCode,
  city,
  accountManagerId
}: {
  stat: DescribedDashboardStat;
  companyId: string;
  vendorIds: string[];
  stateCode: string;
  city: string;
  accountManagerId: string;
}) => {
  const { setStat, setIsLoading } = useContext(DashboardContext);
  const containerRef = useRef<any>();
  const scrollToGrid = useCallback(() => {
    if (containerRef?.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  const grid = useWorkordersGrid({
    forceExpanded: true,
    externalFilters: {
      forCategory: stat.category,
      forCompanyId: companyId,
      forVendors: vendorIds,
      forCity: city,
      forState: stateCode,
      forAccountManager: accountManagerId
    },
    onInitialLoad: () => scrollToGrid(),
    onLoad: () => setIsLoading(false)
  });
  const refresh = grid.refresh;

  useEffect(() => {
    const run = async () => {
      await refresh();
      scrollToGrid();
    };

    void run();
  }, [stat, refresh, scrollToGrid]);

  useEffect(() => {
    void refresh();
  }, [refresh, companyId, vendorIds, city, stateCode, accountManagerId]);

  return (
    <Card ref={containerRef}>
      <CardHeader
        title={`Showing only: ${stat.displayName}`}
        action={
          <RooButton onClick={() => setStat(null, false)} variant={'link'} icon={'undo'}>
            View All
          </RooButton>
        }
      />
      <CardContent>
        <WorkordersGrid state={grid} />
      </CardContent>
    </Card>
  );
};
