import { Grid } from '@mui/material';
import { RooButton, SaveButton } from 'components';
import { FieldDatePicker, FieldNumber, Required } from 'components/form';
import { useRequiredRooForm } from 'components/form/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import {
  EstimateLinesDefinition,
  EstimateRequestViewChange
} from 'pages/Workorders/ViewIssue/Documents/Estimates/types';
import { FormLineItem } from 'pages/Workorders/ViewIssue/Documents/FormLineItem';
import { DocumentFormTotals } from 'pages/Workorders/ViewIssue/Documents/DocumentFormTotals';
import { LineDefinition, TaxableLinesSchema, WithLineCollection } from 'pages/Workorders/ViewIssue/Documents/types';
import { calculateMarkup, DEFAULT_MARKUP_PERCENT } from 'pages/Workorders/ViewIssue/Documents/utils';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { apiProvider } from 'shared/api/apiProvider';
import {
  AttachmentEntityType,
  CreateOrUpdateEstimatePayload,
  FormSubmissionLineItem,
  Estimate,
  LineSource,
  IFormDefaults,
  IEstimate
} from 'shared/api/clients';
import { RooIcon } from 'shared/icons';
import { DeepRequired, rooDate, rooFmt, showSuccess } from 'shared/utils';
import { z } from 'zod';
import { useIsGeneralContractor } from '../../../../../shared/store';

const EstimateSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  estimateId: z.string().nullish(),
  lineCollection: TaxableLinesSchema
});

const makeEmptyLine = (): LineDefinition => {
  return {
    description: null,
    value: null,
    type: LineSource.Custom,
    sourceEntityId: null,
    notes: null,
    initialValue: null,
    resaleValue: null,
    beforeFiles: [],
    afterFiles: [],
    canEditDescription: true,
    canEditValue: true
  };
};

type FormDefinition = DeepRequired<z.infer<typeof EstimateSchema>>;

const getDefaults = (
  estimate: IEstimate,
  lineCollection: EstimateLinesDefinition,
  withTax: boolean,
  formDefaults: IFormDefaults
) => {
  let defaults: FormDefinition = null;
  if (estimate == null) {
    defaults = {
      estimateId: null,
      startDate: null,
      endDate: null,
      lineCollection: {
        taxPercent: lineCollection?.taxPercent == null ? (withTax ? null : 0) : lineCollection.taxPercent,
        discountPercent: lineCollection?.discountPercent,
        resaleTaxPercent: null,
        previousDiscount: null,
        didSetPartialPercent: false,
        didSetPartialAmount: false,
        partialPercent: null,
        partialAmount: null,
        lines: []
      }
    };

    if (lineCollection?.lines != null && lineCollection.lines.length > 0) {
      defaults.startDate = lineCollection.startDate && rooFmt.dateFromInstant(lineCollection.startDate);
      defaults.endDate = lineCollection.endDate && rooFmt.dateFromInstant(lineCollection.endDate);
      lineCollection.lines.forEach((x) =>
        defaults.lineCollection.lines.push({
          description: x.description,
          notes: x.notes,
          value: x.initialValue ? calculateMarkup(x.initialValue, DEFAULT_MARKUP_PERCENT) : x.value,
          initialValue: x.initialValue,
          resaleValue: null,
          sourceEntityId: x.sourceEntityId,
          type: x.sourceEntityType,
          beforeFiles: x.beforeFiles,
          afterFiles: [],
          canEditDescription: true,
          canEditValue: true
        })
      );
    } else {
      defaults.lineCollection.lines.push(makeEmptyLine());
    }
  } else {
    defaults = {
      estimateId: estimate.id,
      startDate: rooFmt.dateFromInstant(estimate.startDate),
      endDate: rooFmt.dateFromInstant(estimate.endDate),
      lineCollection: {
        taxPercent: estimate.totals.taxPercent,
        discountPercent: estimate.totals.discountPercent,
        resaleTaxPercent: null,
        previousDiscount: null,
        partialAmount: null,
        partialPercent: null,
        didSetPartialAmount: false,
        didSetPartialPercent: false,
        lines: estimate.lines.map((x) => ({
          description: x.description,
          notes: x.notes,
          value: x.value,
          type: x.sourceEntityType,
          sourceEntityId: x.sourceEntityId,
          beforeFiles: x.beforeFiles,
          afterFiles: [],
          initialValue: x.initialValue,
          resaleValue: null,
          canEditDescription: true,
          canEditValue: true
        }))
      }
    };
  }

  return defaults;
};

export const EstimateForm = ({
  requestViewChange,
  estimate,
  implicitLines
}: {
  requestViewChange: EstimateRequestViewChange;
  estimate: IEstimate;
  implicitLines: EstimateLinesDefinition;
}) => {
  const { issue, onIssueUpdate, parentIssue, formDefaults } = useCurrentIssue();
  const isGc = useIsGeneralContractor();
  // no more tax on anything requested 9/1/23
  const withTax = false; // parentIssue == null;
  const withDiscount = parentIssue == null && isGc;
  const defaults = getDefaults(estimate, implicitLines, withTax, formDefaults);
  const { handleSubmit, control, getValues, setValue } = useRequiredRooForm(EstimateSchema, {
    defaultValues: defaults
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lineCollection.lines'
  });

  const addLine = () => {
    append(makeEmptyLine(), {
      shouldFocus: false
    });
  };

  const showMarkup =
    estimate == null &&
    implicitLines.lines
      .map((x) => x.sourceEntityType)
      .find((x) => x === LineSource.SubissueEstimateLine || x === LineSource.SubissueBidLine) != null;
  const showInitial = showMarkup || defaults.lineCollection.lines.find((x) => x.initialValue != null) != null;

  const save = async (values: FormDefinition) => {
    try {
      const updated = await apiProvider.issues.estimates.createOrUpdateEstimate(
        new CreateOrUpdateEstimatePayload({
          estimateId: estimate?.id,
          issueId: issue.id,
          startDate: rooDate.makeInstant(values.startDate),
          endDate: rooDate.makeInstant(values.endDate),
          taxPercent: values.lineCollection.taxPercent,
          discountPercent: values.lineCollection.discountPercent ?? 0,
          lines: values.lineCollection.lines.map(
            (x, i) =>
              new FormSubmissionLineItem({
                description: x.description,
                value: x.value,
                initialValue: x.initialValue,
                sourceEntityType: x.type,
                sourceEntityId: x.sourceEntityId,
                notes: x.notes,
                beforeFileIds: x.beforeFiles.map((y) => y.id),
                afterFileIds: [],
                order: i,
                resaleValue: null
              })
          )
        })
      );

      onIssueUpdate(updated);
      showSuccess();
      requestViewChange({ view: 'list' });
    } catch (e) {}
  };

  const startDate = useWatch({ control, name: 'startDate' });

  return (
    <form noValidate onSubmit={handleSubmit(save)}>
      <Grid container spacing={3} mb={4} justifyContent={'center'}>
        <Grid item>
          <FieldDatePicker
            control={control}
            name={'startDate'}
            minDate={new Date()}
            dateFormat={'MMMM d, yyyy'}
            placeholderText={'Start Date'}
            required
            label={'Start Date'}
          />
        </Grid>
        <Grid item>
          <FieldDatePicker
            control={control}
            name={'endDate'}
            minDate={startDate}
            dateFormat={'MMMM d, yyyy'}
            placeholderText={'End Date'}
            required
            label={'End Date'}
          />
        </Grid>
        {showMarkup && (
          <Grid item>
            <MarkupField setValue={setValue} getValues={getValues} />
          </Grid>
        )}
        {withTax && (
          <Grid item>
            <FieldNumber
              control={control}
              name={`lineCollection.taxPercent`}
              required
              label={'Tax Rate %'}
              placeholder={'Tax Rate %'}
            />
          </Grid>
        )}
        {withDiscount && (
          <Grid item>
            <FieldNumber
              control={control}
              name={`lineCollection.discountPercent`}
              required
              label={'Discount %'}
              placeholder={'Discount %'}
            />
          </Grid>
        )}
      </Grid>

      {fields.map((line, index) => {
        const canEdit = parentIssue == null || (line.type !== LineSource.MainTask && line.type !== LineSource.TaskList);
        return (
          <FormLineItem
            key={index}
            line={line}
            index={index}
            remove={remove}
            control={control as unknown as Control<WithLineCollection>}
            getValues={getValues as unknown as UseFormGetValues<WithLineCollection>}
            entityType={AttachmentEntityType.EstimateLineItem}
            canEditDescription={canEdit}
            canEditValue={true}
            hasResale={false}
            showInitial={showInitial}
            hasNotes={true}
            hasBeforeFiles={true}
            hasAfterFiles={false}
          />
        );
      })}
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <Button color={'primary'} onClick={() => addLine()}>
          <RooIcon icon={['fas', 'plus']} /> Add
        </Button>
      </div>

      <div style={{ marginTop: '30px' }}>
        <DocumentFormTotals
          withTax={withTax}
          withResale={false}
          withDiscount={withDiscount}
          withPartial={false}
          previousDiscount={null}
          control={control as unknown as Control<WithLineCollection>}
        />
      </div>

      <hr className="hr-text" />
      <div style={{ textAlign: 'center' }}>
        <SaveButton className={'btn me-2'} control={control}>
          Save
        </SaveButton>
        <RooButton onClick={() => requestViewChange({ view: 'list' })} variant={'secondary'}>
          Cancel
        </RooButton>
      </div>
    </form>
  );
};

const MarkupField = ({
  getValues,
  setValue
}: {
  getValues: UseFormGetValues<FormDefinition>;
  setValue: UseFormSetValue<FormDefinition>;
}) => {
  return (
    <div>
      <label htmlFor={'markup'}>Markup %</label>
      <input
        type="number"
        className={'form-control'}
        step="0.01"
        defaultValue={DEFAULT_MARKUP_PERCENT}
        onChange={(e) => {
          const textValue = e.target.value;
          const number = parseFloat(textValue);
          if (isNaN(number)) {
            return;
          }

          const lines = getValues('lineCollection.lines');
          let idx = 0;
          for (const line of lines) {
            if (line.initialValue != null) {
              const newVal = calculateMarkup(line.initialValue, number);
              setValue(`lineCollection.lines.${idx}.value`, newVal);
            }
            idx += 1;
          }
        }}
        id={'markup'}
      />
    </div>
  );
};
