import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Card, Col } from 'react-bootstrap';
import { EntityType, IssueStatus, ProposalType, ScheduleProposal } from 'shared/api/clients';
import { UserLink } from 'components';
import { rooFmt } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

type ScheduleProposalForEntity = ScheduleProposal & {
  entityType: EntityType;
};

export const ScheduledVisitListCard = () => {
  const { issue, visibleBidSchedules } = useCurrentIssue();
  let schedules: ScheduleProposalForEntity[] = [];
  if (
    issue.schedule != null &&
    (issue.status === IssueStatus.VendorScheduling ||
      issue.status === IssueStatus.WorkInProgress ||
      issue.status === IssueStatus.NeedsInvoice ||
      issue.status === IssueStatus.InvoiceReview ||
      issue.status === IssueStatus.PaymentPending)
  ) {
    schedules = [...schedules, { ...issue.schedule, entityType: EntityType.Issue } as ScheduleProposalForEntity];
  }

  if (issue.status === IssueStatus.Bidding) {
    schedules = [
      ...schedules,
      ...visibleBidSchedules.map((x) => ({ ...x, entityType: EntityType.Bid } as ScheduleProposalForEntity))
    ];
  }

  if (schedules.length === 0) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'}>
      <ScheduledVisitList schedules={schedules} />
    </Col>
  );
};

const ScheduledVisitList = ({ schedules }: { schedules: ScheduleProposalForEntity[] }) => {
  return (
    <Card>
      <Card.Header>Scheduled Visits</Card.Header>
      <Card.Body>
        <DataTable value={schedules}>
          <Column
            field={'scheduleInstant.dateTime'}
            header={'Date'}
            body={(row: ScheduleProposalForEntity) => (
              <>
                {rooFmt.instantDate(row.scheduledInstant)} {row.timeBlocks[0].display}
              </>
            )}
          />
          <Column
            header={'Person'}
            body={(row: ScheduleProposalForEntity) => <UserLink role={'Vendor'} user={row.proposedByUser} />}
          />
          <Column header={'Notes'} field={'notes'} />
          <Column
            header={'Type'}
            field={'proposalType'}
            body={(row: ScheduleProposalForEntity) =>
              row.proposalType === ProposalType.Final ? 'Scheduled' : 'Proposal'
            }
          />
          <Column
            header={'Reason'}
            field={'entityType'}
            body={(row: ScheduleProposalForEntity) => (row.entityType === EntityType.Issue ? 'Work' : 'Assessment')}
          />
        </DataTable>
      </Card.Body>
    </Card>
  );
};
