import { Alert, Button, Divider, FormGroup, Grid } from '@mui/material';
import { PasswordModal } from 'components/PasswordModal';
import { FieldMuiText, FieldSingleSelect, Required, useRooForm } from 'components/form';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { apiProvider } from 'shared/api/apiProvider';
import {
  DwollaCustomerInfo,
  DwollaMasterInfo,
  DwollaTransferInfo,
  StripeAccountInfo,
  Vendor
} from 'shared/api/clients';
import { useAppStore, useCurrentUser, useCurrentVendor, useIsGeneralContractor } from 'shared/store';
import { rooFmt, showSuccess } from 'shared/utils';
import { z } from 'zod';
import { ReactSelectCommonStyles } from '../../components/RooSelect';
import { useDisclosure } from '@roo/lib';

export const DwollaBankAccountSchema = z.object({
  routingNumber: z.string(),
  accountNumber: z.string(),
  accountType: z.string(),
  accountName: z.string()
});
type DwollaBankAccountForm = z.infer<typeof DwollaBankAccountSchema>;

export const SectionDwolla = ({ vendor, onCancel }: { vendor: Vendor; onCancel: () => any }) => {
  const currentUser = useCurrentUser();
  const currentVendor = useCurrentVendor();
  const isGc = useIsGeneralContractor();

  const [customer, setCustomer] = useState<DwollaCustomerInfo>();

  const [additionalAuth, onAdditionalAuth] = useAppStore((x) => {
    return [x.additionalAuth, x.actions.onAdditionalAuth];
  });

  const pwdModal = useDisclosure(false, {
    onClose: () => {
      if (!additionalAuth) onCancel();
    }
  });

  const [accountInfo, setAccountInfo] = useState<StripeAccountInfo>({} as StripeAccountInfo);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const getDwollaCustomerInfo = async () => {
      let dc = await apiProvider.profileClient.getDwollaCustomerInfoForVendor(vendor.id);
      if (!dc?.id) {
        dc = {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.contactInfo.email
        } as any;
      }
      setCustomer(dc);
    };
    void getDwollaCustomerInfo();
  }, [vendor.id]);

  const onAddFundingSource = async (values: DwollaBankAccountForm) => {
    let dc = await apiProvider.profileClient.addDwollaFundingSource({
      ...values,
      vendorId: vendor.id
    } as any);
    setCustomer(dc);
    showSuccess('Bank account was successfully added!');
  };

  const onRemoveFundingSource = async () => {
    let dc = await apiProvider.profileClient.removeFundingSource(vendor.id);
    setCustomer(dc);
    showSuccess('Bank account was successfully removed!');
  };

  useEffect(() => {
    if (!additionalAuth) {
      pwdModal.open();
    }
  }, [additionalAuth]);

  useEffect(() => {
    setLoading(true);
    const getAccountInfo = async () => {
      var ai = await apiProvider.profileClient.getStripeAccountInfoForVendor(currentVendor.id);
      setAccountInfo(ai);
      setLoading(false);
    };
    void getAccountInfo();
  }, []);

  const connectToStripe = async () => {
    setLoading(true);
    var url: string = await apiProvider.profileClient.connectVendorToStripe(currentVendor.id);
    window.location = url as any;
  };

  const query = new URLSearchParams(window.location.search);
  let sreauth = query.get('stripereauth') as any;
  if (sreauth) {
    connectToStripe();
  }

  return (
    <>
      {additionalAuth ? (
        <Grid
          component={'form'}
          noValidate
          container
          direction="row"
          justifyContent="center"
          alignItems="top"
          sx={{ mt: 0, p: 3, mb: 4 }}
          spacing={2}
        >
          <Grid item xs={12}>
            <h5 style={{ color: 'black' }}>
              <strong>Stripe</strong>
            </h5>
            <Divider />
          </Grid>
          {(!accountInfo || !accountInfo.detailsSubmitted) && (
            <Grid item xs={12} md={12}>
              <Button
                type="button"
                disabled={loading}
                variant="contained"
                sx={{ mt: 2, mb: 2, width: 200, fontWeight: 700 }}
                className="Button"
                onClick={connectToStripe}
              >
                {!accountInfo && 'Connect to stripe'}
                {accountInfo && !accountInfo.detailsSubmitted && 'Finish connection'}
                {accountInfo && accountInfo.detailsSubmitted && 'Open Stripe dashboard'}
              </Button>
            </Grid>
          )}
          {accountInfo && accountInfo.detailsSubmitted && (
            <Grid item xs={12} md={12}>
              Your profile is connected to Stripe!{' '}
            </Grid>
          )}
          {accountInfo && accountInfo.detailsSubmitted && !accountInfo.chargesEnabled && (
            <Grid item xs={12} md={12}>
              Payments are not yet ennabled! Please check your Stripe dashboard!
            </Grid>
          )}
          {isGc ? (
            <>
              <DwollaMaster />
              <DwollaTransfers />
            </>
          ) : (
            <>
              {!!customer && (
                <DwollaBankAccount customer={customer} onSave={onAddFundingSource} onRemove={onRemoveFundingSource} />
              )}
            </>
          )}
          {!!customer && customer.bankAccount && <DwollaTransfers />}
        </Grid>
      ) : (
        <PasswordModal modal={pwdModal} onSuccess={onAdditionalAuth} />
      )}
    </>
  );
};

export const DwollaTransfers = () => {
  const [data, setData] = useState<DwollaTransferInfo[]>([]);
  const columns = useMemo<MRT_ColumnDef<DwollaTransferInfo>[]>(
    () => [
      {
        header: 'Created',
        accessorKey: 'created',
        Cell: ({ cell }) => rooFmt.dateMaybe(cell.getValue<Date>(), 'N/A')
      },
      {
        header: 'Source',
        accessorKey: 'source'
      },
      {
        header: 'Destination',
        accessorKey: 'destination'
      },
      {
        header: 'Status',
        accessorKey: 'status'
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        Cell: ({ cell }) => rooFmt.moneyMaybe(cell.getValue<number>(), 'N/A')
      },
      {
        header: 'Address1',
        accessorKey: 'address1'
      }
    ],
    []
  );
  useEffect(() => {
    const lstTransfers = async () => {
      let d = await apiProvider.profileClient.listDwollaTransfers();
      setData(d);
    };
    void lstTransfers();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <h5 style={{ color: 'black' }}>
          <strong>Dwolla Transactions</strong>
        </h5>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MaterialReactTable
          columns={columns}
          enablePagination={false}
          data={data ?? []}
          state={{ density: 'compact' }}
          initialState={{
            sorting: [
              {
                id: 'created',
                desc: true
              }
            ]
          }}
          // muiTableContainerProps={{
          //   ref: tableContainerRef,
          //   sx: { maxHeight: `${height}px` }
          // }}
          enableGlobalFilter={false}
          enableStickyHeader={true}
          enableHiding={false}
          enableColumnDragging={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableTopToolbar={false}
        />
      </Grid>
    </>
  );
};

export const DwollaBankAccount = ({
  customer,
  onSave,
  onRemove
}: {
  customer: DwollaCustomerInfo;
  onSave: (bankAccount: DwollaBankAccountForm) => void;
  onRemove: () => void;
}) => {
  const { control, handleSubmit } = useRooForm(DwollaBankAccountSchema, {
    defaultValues: {
      accountName: customer.bankAccount?.accountName,
      accountType: customer.bankAccount?.accountType
      //routingNumber: '222222226' //just for testing
    }
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    await handleSubmit(onSave)();
    setIsLoading(false);
  };

  const handleRemove = async () => {
    setIsLoading(true);
    await onRemove();
    setIsLoading(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <h5 style={{ color: 'black' }}>
          <strong>Dwolla Bank Account</strong>
        </h5>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">
          Bank account won't connect? Try this:
          <br />
          1) First and last name need to match the first and last name of one of the bank account owner.
          <br />
          2) Double check to make sure it is an ACH routing number vs a wire routing number.
        </Alert>
      </Grid>
      <Grid item xs={12} md={6} alignItems="center">
        <FieldMuiText
          control={control}
          name={'accountName'}
          label="Account Name"
          placeholder={'Enter the accounts Name'}
          fullWidth
          margin="normal"
          required
          disabled={!!customer.bankAccount?.id}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldSingleSelect
          groupProps={{ style: { marginTop: '16px' } }}
          control={control}
          name={'accountType'}
          options={[
            { value: 'checking', label: 'Checking' },
            { value: 'savings', label: 'Savings' }
          ]}
          placeholder={'Account Type'}
          isDisabled={!!customer.bankAccount?.id}
          styles={ReactSelectCommonStyles.MuiLookAlike}
        />
      </Grid>
      {!customer.bankAccount?.id && (
        <>
          <Grid item xs={12} md={6}>
            <FieldMuiText
              control={control}
              name={'routingNumber'}
              label="Routing Number"
              placeholder={'Enter the routing number'}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldMuiText
              control={control}
              name={'accountNumber'}
              label="Account Number"
              placeholder={'Enter the Account Number'}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
        </>
      )}
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        {customer.bankAccount?.id ? (
          <Button type="submit" sx={{ mt: 2, mb: 2, width: 200 }} disabled={isLoading} onClick={handleRemove}>
            Remove Bank Account
          </Button>
        ) : (
          <Button type="submit" sx={{ mt: 2, mb: 2, width: 200 }} disabled={isLoading} onClick={handleSave}>
            Add Bank Account
          </Button>
        )}
      </Grid>
    </>
  );
};

export const DwollaMaster = () => {
  const [masterInfo, setMasterInfo] = useState<DwollaMasterInfo>(null);
  const [working, setWorking] = useState<boolean>(false);

  const getInfo = async () => {
    const mi = await apiProvider.profileClient.getDwollaMasterInfo();
    setMasterInfo(mi);
  };

  useEffect(() => {
    void getInfo();
  }, []);

  const onSimulate = async () => {
    setWorking(true);
    try {
      let result = await apiProvider.profileClient.simulateDwolla();
      if (result.total > 0) showSuccess(`${result.total} transaction was processed`);
      else showSuccess('No transaction was processed!');
    } finally {
      await getInfo();
      setWorking(false);
    }
  };

  const onAddToMaster = async () => {
    setWorking(true);
    try {
      await apiProvider.profileClient.addToMasterBalanceIfNeeded();
      showSuccess('Success!');
    } finally {
      await getInfo();
      setWorking(false);
    }
  };

  return (
    masterInfo !== null && (
      <>
        <Grid item xs={12}>
          <h5 style={{ color: 'black' }}>
            <strong>Balance</strong>
          </h5>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <h6>Available: {masterInfo.available}</h6>
        </Grid>
        <Grid item xs={12} md={6}>
          <h6>Pending: {masterInfo.pending}</h6>
        </Grid>
        <Grid item xs={12} md={12}>
          <h6>
            <strong>Total: {masterInfo.total}</strong>
          </h6>
        </Grid>
        <Grid item xs={12} md={12}>
          {masterInfo && masterInfo.isSandbox && (
            <Button type="button" sx={{ mt: 2, mb: 2, mr: 2, width: 200 }} disabled={working} onClick={onSimulate}>
              Simulate Transactions
            </Button>
          )}
          {masterInfo && masterInfo.total < masterInfo.minimalAmount && (
            <Button type="button" sx={{ mt: 2, mb: 2, width: 400 }} disabled={working} onClick={onAddToMaster}>
              Transfer {masterInfo.amountToAdd} to master balance
            </Button>
          )}
        </Grid>
      </>
    )
  );
};
