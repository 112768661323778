import { Alert, Box, Button, Grid, ThemeProvider, Typography } from '@mui/material';
import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { CreateCompanyWithOwnerPayload, CreatePersonModel } from 'shared/api/clients';
import { FieldMuiText, useRooForm } from 'components/form';
import { LegalLinks, Routes } from 'shared/routing';
import theme, { darkTheme } from 'shared/theme';
import { RouterLink } from 'components';
import { PasswordConfirmationSchema, PersonSchema, useFullScreen } from 'shared/utils';
import { z } from 'zod';
import { AuthPage } from 'pages/Auth/AuthPage';
import { Center } from '../../components/Center';

const FormSchema = z
  .object({
    person: PersonSchema,
    passwords: PasswordConfirmationSchema,
    companyName: z.string()
  })
  .superRefine((val, ctx) => {
    if (
      !!val.person.email &&
      !!val.passwords.newPassword &&
      val.passwords.newPassword.toLowerCase().indexOf(val.person.email.toLowerCase()) > -1
    ) {
      ctx.addIssue({
        message: "The passsword can't contain the email address!",
        code: 'custom',
        path: ['passwords', 'newPassword']
      });
      return;
    }
  });
type FormDefinition = z.infer<typeof FormSchema>;

type State = {
  error?: string;
  status: 'idle' | 'loading' | 'error' | 'done';
};

export const Register = () => {
  useFullScreen();
  const [state, setState] = useState<State>({
    status: 'idle'
  });

  const { control, handleSubmit } = useRooForm(FormSchema);

  const handleSignup = async (values: FormDefinition) => {
    setState({ status: 'loading' });
    try {
      const result = await apiProvider.authClient.register(
        new CreateCompanyWithOwnerPayload({
          companyName: values.companyName,
          person: new CreatePersonModel({
            email: values.person.email,
            firstName: values.person.firstName,
            lastName: values.person.lastName,
            phone: values.person.phone,
            userId: null
          }),
          password: values.passwords.confirmPassword,
          autoConfirmOwnerEmail: false,
          muteCompanyNotifications: false,
          muteOwnerNotifications: false
        })
      );

      if (result.success) {
        setState({ status: 'done' });
        // authManager.logIn(result.authToken, true);
        // window.location.href = Routes.Dashboard;
      } else {
        setState({ status: 'error', error: result.error });
      }
    } catch (err) {}
  };

  const createText = 'Create Account';

  return (
    <AuthPage title={'Register - Walkthroo'} minWidth={650}>
      {state.status === 'done' && (
        <Center>
          <Alert severity={'error'} title={'Registration is disabled'}>
            Registration is currently disabled, but we've sent your request to our team. We'll get back to you soon.
          </Alert>
        </Center>
      )}
      {state.status !== 'done' && (
        <form noValidate onSubmit={handleSubmit(handleSignup)}>
          <Box className="d-flex flex-column align-items-center">
            <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: 700 }}>
              Register
            </Typography>
            <Typography variant="subtitle1" component="div" gutterBottom>
              Enter your details below to create your account
            </Typography>
          </Box>
          <ThemeProvider theme={darkTheme}>
            <Grid container spacing={[0, 0, 2]}>
              <Grid item xs={12} md={6}>
                <FieldMuiText
                  control={control}
                  className="Input-dark"
                  name={'person.firstName'}
                  margin="normal"
                  label="First Name"
                  type="text"
                  placeholder="Enter your First Name"
                  required
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldMuiText
                  control={control}
                  className="Input-dark"
                  name={'person.lastName'}
                  margin="normal"
                  label="Last Name"
                  type="text"
                  placeholder="Enter your Last Name"
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={[0, 0, 2]}>
              <Grid item xs={12} md={6}>
                <FieldMuiText
                  control={control}
                  name={'person.email'}
                  className="Input-dark"
                  margin="normal"
                  label="Email"
                  type="email"
                  placeholder="Enter your Email"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldMuiText
                  control={control}
                  name={'person.phone'}
                  className="Input-dark"
                  margin="normal"
                  label="Phone"
                  type="text"
                  placeholder="Enter your phone"
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={[0, 0, 2]}>
              <Grid item xs={12}>
                <FieldMuiText
                  control={control}
                  name={'companyName'}
                  className="Input-dark"
                  margin="normal"
                  label="Company Name"
                  type="text"
                  placeholder="Enter your Company Name"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={[0, 0, 2]}>
              <Grid item xs={12} md={6}>
                <FieldMuiText
                  type={'password'}
                  control={control}
                  name={'passwords.newPassword'}
                  className="Input-dark"
                  margin="normal"
                  label="Password"
                  autoComplete="current-password"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldMuiText
                  type={'password'}
                  control={control}
                  name={'passwords.confirmPassword'}
                  className="Input-dark"
                  margin="normal"
                  label="Confirm Password"
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Typography sx={{ mt: 2 }}>
              By clicking "{createText}", you agree to our{' '}
              <a
                href={LegalLinks.Privacy}
                target="_blank"
                style={{ color: theme.palette.primary.main }}
                rel="noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href={LegalLinks.TermsOfUse}
                target="_blank"
                style={{ color: theme.palette.primary.main }}
                rel="noreferrer"
              >
                Terms of Use Agreement
              </a>
            </Typography>
            <Typography textAlign={'center'}>
              You also consent to receiving job related texts. You may opt-out in your profile.
            </Typography>
          </ThemeProvider>
          <Button type="submit" fullWidth size={'largest'} sx={{ mt: 2, mb: 2 }} onClick={handleSubmit(handleSignup)}>
            {createText}
          </Button>
          <Box sx={{ mt: 3 }}>{state.status === 'error' && <Alert severity="error">{state.error}</Alert>}</Box>
          <Grid container className="d-flex justify-content-center mt-3">
            <Grid item>
              Already have an account?&nbsp;
              <RouterLink to={Routes.LogIn} style={{ color: theme.palette.primary.main }}>
                Login
              </RouterLink>
            </Grid>
          </Grid>
        </form>
      )}
    </AuthPage>
  );
};
