import { orderBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { CapabilityType, LookupItem, TimeBlock, WorkorderStatus } from 'shared/api/clients';
import { EnumMapIssueStatus } from 'shared/store/enums/issueStatus';
import { rooFmt } from '../utils';
import { useAppStore } from './appStore';
import { PropertyGroup } from './properties';
import { useCurrentVendor } from './storeHooks';
import { EnumMapInspectionStatus } from './enums/inspectionStatus';

export type GroupedDropdownOption<T> = {
  label: string;
  options: DropdownOption<T>[];
};

export type DropdownOption<T> = {
  label: string;
  value: T;
};

export type DropdownOptionWithData<TValue, TData> = DropdownOption<TValue> & { data: TData };

export const useDropdownValues = <TVal>(items: { id: TVal; name: string; order: number }[]) => {
  const options: DropdownOption<TVal>[] = useMemo(() => {
    const ordered = orderBy(items, ['pinned', 'name'], ['desc', 'asc']);
    return [
      ...ordered.map((x) => ({
        label: x.name,
        value: x.id
      }))
    ];
  }, [items]);

  return options;
};

export const usePropertyUnitOptions = () => {
  const properties = useAppStore((x) => x.properties);
  const prepProps = useMemo(
    () => properties.filter((x) => x.isActive).map((x) => ({ id: x.id, name: x.address.address2, order: null })),
    [properties]
  );
  return useDropdownValues(prepProps);
};

export const usePropertyGroupOptions = () => {
  const propertyGroups = useAppStore((x) => x.propertyGroups);
  const options: DropdownOptionWithData<string, PropertyGroup>[] = useMemo(() => {
    const ordered = orderBy(propertyGroups, ['display'], ['desc', 'asc']);
    return [
      ...ordered.map((x) => ({
        label: x.display,
        data: x,
        value: x.key
      }))
    ];
  }, [propertyGroups]);

  return options;
};

export const useTimeBlockOptions = () => {
  const options = useMemo(() => {
    const firstHour = 8;
    const lastHour = 18;
    const intervalHrs = 2;

    const opts = new Array<DropdownOption<TimeBlock>>();
    let current = firstHour;
    while (current <= lastHour) {
      const block = new TimeBlock({
        startHour: current,
        startMinutes: 0,
        durationMinutes: intervalHrs * 60,
        display: null
      });

      const option: DropdownOption<TimeBlock> = {
        value: block,
        label: rooFmt.timeBlock(block)
      };
      opts.push(option);

      current += intervalHrs;
    }

    return opts;
  }, []);

  return options;
};

export const priorityOptions: DropdownOption<number>[] = [
  {
    value: 1,
    label: 'Critical'
  },
  {
    value: 2,
    label: 'High'
  },
  {
    value: 3,
    label: 'Medium'
  },
  {
    value: 4,
    label: 'Low'
  },
  {
    value: 5,
    label: 'Trivial'
  }
];

export const workorderStatusOptions: DropdownOption<number>[] = [
  {
    value: WorkorderStatus.New,
    label: 'New'
  },
  {
    value: WorkorderStatus.InProgress,
    label: 'In Progress'
  },
  {
    value: WorkorderStatus.Closed,
    label: 'Closed'
  }
];

export const issueStatusOptions: DropdownOption<number>[] = Array.from(EnumMapIssueStatus.entries()).map(([k, v]) => ({
  value: k,
  label: v.display
}));

export const inspectionStatusOptions: DropdownOption<number>[] = Array.from(EnumMapInspectionStatus.entries()).map(
  ([k, v]) => ({
    value: k,
    label: v.display
  })
);

export const useLookupOptions = (items: LookupItem[]) => {
  const options: DropdownOption<number>[] = useMemo(() => {
    const filtered = items.filter((x) => !x.obsolete);
    const ordered = orderBy(filtered, ['order', 'name'], ['asc', 'asc']);
    return [
      ...ordered.map((x) => ({
        label: x.name,
        value: x.id
      }))
    ];
  }, [items]);

  return options;
};

export const useStateOptions = () => {
  const states = useAppStore((x) => x.lookups.states);
  const mapped = useMemo(() => states.map((x) => ({ id: x.code, name: x.name, order: null })), [states]);
  return useDropdownValues<string>(mapped);
};

export const useAccountManagersOptions = () => {
  const vendor = useCurrentVendor();

  const handlerOptions = useMemo(
    () =>
      vendor?.workingUsers.map((x) => ({
        label: x.fullName,
        value: x.id
      })) satisfies DropdownOption<string>[],
    [vendor?.users]
  );

  return handlerOptions;
};

export const useCapabilityOptions = (): DropdownOption<CapabilityType>[] => {
  return [
    { value: CapabilityType.ManagementCompanyOwner, label: 'Management Company Owner' },
    { value: CapabilityType.PropertyManager, label: 'Property Manager' },
    { value: CapabilityType.Vendor, label: 'Vendor' },
    { value: CapabilityType.Tenant, label: 'Tenant' },
    { value: CapabilityType.PropertyOwner, label: 'Property Owner' }
  ];
};

export const useFocusOptions = () => {
  const options = useAppStore((x) => x.lookups.focusTypes);
  return useLookupOptions(options);
};

export const useInteriorLocationOptions = () => {
  const options = useAppStore((x) => x.lookups.interiorLocations);
  return useLookupOptions(options);
};

export const useExteriorLocationOptions = () => {
  const options = useAppStore((x) => x.lookups.exteriorLocations);
  return useLookupOptions(options);
};
