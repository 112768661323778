import { RooButton, RooDialog, SaveButton } from 'components';
import { FieldDatePicker, FieldNumber, FieldSingleSelect, FieldSwitch, FieldText, useRooForm } from 'components/form';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { Control, useFieldArray } from 'react-hook-form';
import { apiProvider } from 'shared/api/apiProvider';
import { IInvoice, InvoiceStatus, Payment, PaymentSource } from 'shared/api/clients';
import { z } from 'zod';
import { rooFmt } from 'shared/utils';
import { ReactNode, useEffect, useState } from 'react';
import { useAppStore, useCurrentUser, useCurrentVendor } from 'shared/store';
import { PasswordModal } from 'components/PasswordModal';
import { Stack } from '@mui/material';
import { useDisclosure, useForceUpdate } from '@roo/lib';

type FormDefinition = {
  useDwolla?: boolean;
  date?: Date;
  amount?: number;
  notes?: string;
  invoices?: {
    allocatedAmount?: number;
    invoiceId?: string;
  }[];
};

export const PaymentModal = ({
  payment,
  visible,
  onCloseRequest,
  onSaved,
  title
}: {
  payment?: Payment;
  visible: boolean;
  onCloseRequest?: () => any;
  onSaved?: (p: Payment) => any;
  title: ReactNode;
}) => {
  const { issue, onIssueUpdate, visibleInvoices } = useCurrentIssue();
  const invoiceLookup: { [key: string]: IInvoice } = {};

  const currentVendor = useCurrentVendor();
  const currentUser = useCurrentUser();
  const isTestUser = currentUser?.id === '2b2e8ad6-3772-4e19-a864-55db16a5eec5' ?? false;
  const isGeneralContractor = currentVendor?.isGeneralContractor ?? false;

  const [canPayWithDwolla, setCanPayWithDwolla] = useState(isTestUser || isGeneralContractor);

  const forceUpdate = useForceUpdate();

  visibleInvoices.forEach((inv) => {
    invoiceLookup[inv.id] = inv;
  });

  const FormSchema = z
    .object({
      useDwolla: z.boolean(),
      date: z.date(),
      amount: z.number(),
      notes: z.string().nullish(),
      invoices: z.array(
        z
          .object({
            invoiceId: z.string(),
            allocatedAmount: z.number()
          })
          .refine(
            (val) => {
              let outstanding = invoiceLookup[val.invoiceId].outstanding;
              const pi = payment?.invoices?.find((a) => a.invoiceId === val.invoiceId);
              if (pi != null) {
                outstanding += pi.allocatedAmount;
              }
              console.log(
                val.allocatedAmount,
                outstanding,
                invoiceLookup[val.invoiceId].outstanding,
                pi?.allocatedAmount
              );
              return !val.invoiceId || outstanding >= val.allocatedAmount;
            },
            { message: 'The amount must be less than the outstanding amount on invoice', path: ['allocatedAmount'] }
          )
      )
    })
    .refine(
      (data) => {
        let sum = 0;
        data.invoices?.forEach((val) => {
          sum += val.allocatedAmount;
        });
        return sum <= data.amount;
      },
      { message: 'The payment amount has to be greater or equal than the sum of allocated amounts!', path: ['amount'] }
    );

  const defaultValue: FormDefinition = {
    amount: undefined,
    useDwolla: canPayWithDwolla && !payment,
    date: new Date(),
    invoices: [],
    notes: undefined
  };

  const { handleSubmit, control, formState, reset, getValues, setValue } = useRooForm(FormSchema, {
    defaultValues: defaultValue
  });

  useEffect(() => {
    const getInfo = async () => {
      if (canPayWithDwolla && visible) {
        let result = await apiProvider.paymentsClient.canVendorAccpetDwollaPayment(issue.vendor?.id);
        setCanPayWithDwolla(canPayWithDwolla && result);
      }
    };
    void getInfo();
  }, [currentVendor, issue, visible]);

  useEffect(() => {
    reset(
      payment
        ? {
            ...payment,
            date: payment.date?.dateTime,
            useDwolla: false
          }
        : defaultValue
    );
  }, [payment]);

  const useDwolla = getValues().useDwolla;

  useEffect(() => {
    // hack to force the useEffect spaghetti to properly clear this
    // otherwise useDwolla won't properly get set to false since we're updating canPayWithDwolla too late
    if (useDwolla && (!canPayWithDwolla || payment != null)) {
      setValue('useDwolla', false, { shouldTouch: true });
      forceUpdate();
    }
  }, [useDwolla, canPayWithDwolla, payment]);

  const [additionalAuth, onAdditionalAuth] = useAppStore((x) => {
    return [x.additionalAuth, x.actions.onAdditionalAuth];
  });

  const pwdModal = useDisclosure(false, {
    onClose: () => {}
  });

  const [callback, setCallback] = useState<() => any>(() => () => {});

  const doSubmit = async () => {
    try {
      const value = getValues();
      const retval = await apiProvider.paymentsClient.addOrUpdatePayment({
        id: payment?.id,
        amount: value.amount,
        date: value.date,
        notes: value.notes,
        issueId: issue.id,
        invoices: value.invoices,
        useDwolla: !payment && value.useDwolla
      } as any);
      onCloseRequest();
      onIssueUpdate(retval);
    } catch (err) {}
  };

  const submit = (value: FormDefinition) => {
    if (canPayWithDwolla && !payment && value.useDwolla && !additionalAuth) {
      return new Promise((resolve, reject) => {
        setCallback(() => async () => {
          onAdditionalAuth();
          await doSubmit();
          resolve(undefined);
        });
        pwdModal.open();
      });
    } else {
      return doSubmit();
    }
  };

  return (
    <RooDialog maxWidth={'sm'} fullWidth open={visible} onClose={onCloseRequest}>
      <Form noValidate style={{ marginBottom: 'initial' }} onSubmit={handleSubmit(submit)}>
        <RooDialog.Title onClose={onCloseRequest}>{title}</RooDialog.Title>
        <RooDialog.Content>
          <Stack spacing={4}>
            <Stack spacing={2}>
              {!payment && canPayWithDwolla && (
                <FieldSwitch control={control} name={'useDwolla'} label="Pay with dwolla" disabled={!!payment} />
              )}
              {!!payment && payment.paymentSource === PaymentSource.Dwolla && <FormGroup>Paid with dwolla</FormGroup>}
              {!getValues().useDwolla && (
                <FieldDatePicker
                  control={control}
                  name={'date'}
                  label="Date"
                  disabled={
                    payment?.paymentSource === PaymentSource.Dwolla || payment?.paymentSource === PaymentSource.Stripe
                  }
                />
              )}
              <FieldNumber
                control={control}
                name={'amount'}
                label="Amount"
                disabled={
                  payment?.paymentSource === PaymentSource.Dwolla || payment?.paymentSource === PaymentSource.Stripe
                }
              />
              <FieldText control={control} name={'notes'} label="Notes" />
            </Stack>
            <InvoiceList control={control} getValues={getValues} payment={payment}></InvoiceList>
          </Stack>
        </RooDialog.Content>
        <RooDialog.Actions>
          <SaveButton control={control}>{getValues().useDwolla ? 'Pay' : 'Save'}</SaveButton>
          <Button
            disabled={formState.isSubmitting || formState.isValidating}
            variant="secondary"
            onClick={onCloseRequest}
          >
            Cancel
          </Button>
        </RooDialog.Actions>
      </Form>
      {!additionalAuth && <PasswordModal modal={pwdModal} onSuccess={callback} />}
    </RooDialog>
  );
};

const InvoiceList = ({
  control,
  getValues,
  payment
}: {
  control: Control<FormDefinition>;
  getValues: () => any;
  payment: Payment;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invoices'
  });
  const { visibleInvoices } = useCurrentIssue();

  const selectedInvoices: string[] = getValues().invoices?.map((i: any) => i.invoiceId) ?? [];

  const availableOptions = visibleInvoices
    .filter(
      (x) =>
        x.status !== InvoiceStatus.Edited && x.status !== InvoiceStatus.Rejected && x.status !== InvoiceStatus.Draft
    )
    .map((i) => {
      const pi = payment?.invoices?.filter((a) => a.invoiceId === i.id);
      const currentlyAllocated = pi?.length ? pi[0].allocatedAmount : 0;
      return {
        label: i.friendlyId + ` (${rooFmt.money(i.outstanding + currentlyAllocated)})`,
        value: i.id,
        disabled: selectedInvoices.indexOf(i.id) !== -1
      };
    });

  const addInvoice = () => {
    append(
      {
        invoiceId: null,
        allocatedAmount: null
      },
      {
        shouldFocus: true
      }
    );
  };

  return (
    <>
      {fields.length > 0 && (
        <Row>
          <Col sm={6}>Invoice</Col>
          <Col sm={4}>Amount</Col>
        </Row>
      )}
      {fields.map((invoice, index) => (
        <Row key={invoice.id}>
          <Col sm={6}>
            <FieldSingleSelect
              control={control}
              name={`invoices.${index}.invoiceId`}
              options={availableOptions}
              isOptionDisabled={(option: any) => option.disabled}
            />
          </Col>
          <Col sm={4}>
            <FieldNumber control={control} name={`invoices.${index}.allocatedAmount`}></FieldNumber>
          </Col>
          <Col sm={2}>
            <RooButton
              variant={'danger'}
              icon={'times'}
              onClick={() => {
                remove(index);
              }}
            ></RooButton>
          </Col>
        </Row>
      ))}
      <div>
        <RooButton
          variant={'success'}
          //size={'md'}
          className={'float-start slim'}
          icon={'plus'}
          onClick={() => addInvoice()}
        >
          Assign to invoice(s)
        </RooButton>
      </div>
    </>
  );
};
