import { useEffect, useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { IIssue, IssueActivityLogEntry, IssueRole } from 'shared/api/clients';
import { ActivityTimeline, LoaderSmall, TimelineEntry } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { EnumMapIssueActivityType } from 'pages/Workorders/ViewIssue/activityLog';
import { MuiIcon } from '../../../shared/icons';
import { useIsGeneralContractor } from '../../../shared/store';

const formatActivity = ({ log, issue }: { log: IssueActivityLogEntry; issue: IIssue }) => {
  const entry: TimelineEntry = {
    id: log.id,
    date: log.modifiedAt,
    color: 'primary',
    Icon: MuiIcon.QuestionMark,
    text: <>Unknown event</>,
    notes: log.notes
  };

  const mapped = EnumMapIssueActivityType.get(log.activityType);
  entry.color = mapped.color;
  entry.Icon = mapped.Icon;
  entry.text = mapped.textFormatter(log, issue);

  return entry;
};

export const IssueActivityLog = () => {
  const { issue } = useCurrentIssue();
  const [{ logs, loading }, setstate] = useState({ logs: new Array<IssueActivityLogEntry>(), loading: true });
  useEffect(() => {
    const load = async () => {
      const items = await apiProvider.issues.main.getActivityLog(issue.id);
      await setstate({
        loading: false,
        logs: items
      });
    };
    void load();
  }, [issue.id]);

  let body = <LoaderSmall />;

  if (!loading) {
    if (!logs.length) {
      body = (
        <div style={{ textAlign: 'center' }}>
          <h4>No entries</h4>
        </div>
      );
    } else {
      body = <FormattedTimeline issue={issue} logs={logs} />;
    }
  }

  return (
    <Card>
      <Card.Header>Activity Log</Card.Header>
      <Card.Body>{body}</Card.Body>
    </Card>
  );
};

const FormattedTimeline = ({ logs, issue }: { logs: IssueActivityLogEntry[]; issue: IIssue }) => {
  const { userRole } = useCurrentIssue();
  const isGc = useIsGeneralContractor();
  const isManager = userRole === IssueRole.MainPropertyManager || userRole === IssueRole.AltPropertyManager;
  const isAdmin = userRole === IssueRole.AdminViewer;
  const showNotifications = isGc || isManager || isAdmin;
  const entries = useMemo(() => logs.map((log) => formatActivity({ log, issue })), [logs, issue]);
  return <ActivityTimeline entries={entries} showNotificationBtn={showNotifications} />;
};
