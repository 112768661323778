import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import { CreateVendorPayload, ModifyAddressPayload, RooPermission } from 'shared/api/clients';
import { useRooForm } from 'components/form/utils';
import { redirect, Routes } from 'shared/routing';
import { useCurrentUser, useHasPermission, useIsGeneralContractor } from 'shared/store';
import { ContentWrapper, NavSlim } from 'components';
import { showSuccess } from 'shared/utils';
import { FormDefinition, FormSchema } from 'pages/UserLists/Vendors/forms';
import { VendorForm } from 'pages/UserLists/Vendors/VendorForm';

export const VendorCreate = () => {
  const { handleSubmit, formState, control } = useRooForm(FormSchema, {
    defaultValues: {
      vendorFocuses: []
    }
  });

  const currentUser = useCurrentUser();
  const isGc = useIsGeneralContractor();

  const save = async (values: FormDefinition) => {
    try {
      await apiProvider.vendorClient.create(
        new CreateVendorPayload({
          cellPhone: values.contactCellPhone,
          email: values.contactEmail,
          companyName: values.company,
          focusIds: values.vendorFocuses,
          workPhone: values.contactWorkPhone,
          managementCompanyId: isGc ? null : currentUser.managementCompany.id,
          contactFirstName: values.contactFirstName,
          contactLastName: values.contactLastName,
          address: new ModifyAddressPayload({
            address1: values.address.address1,
            address2: values.address.address2,
            city: values.address.city,
            zipCode: values.address.zipCode,
            state: values.address.state
          })
        })
      );
      redirect({ pathname: Routes.VendorList });
      showSuccess();
    } catch (err) {}
  };

  const canAddEditVendors = useHasPermission(RooPermission.AddEditVendors, currentUser.managementCompany?.id) || isGc;

  return (
    <>
      <Helmet>
        <title>Create Vendor - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Vendors', destination: Routes.VendorList }, { text: 'New Vendor' }]} />
      <ContentWrapper>
        {canAddEditVendors ? (
          <Form onSubmit={handleSubmit(save)}>
            <VendorForm control={control} formState={formState} isEditing={false} />
          </Form>
        ) : (
          'This user does not have access to this page.'
        )}
      </ContentWrapper>
    </>
  );
};
