import { AxiosInstance } from "axios";
import {
  AdminClient,
  AssignmentsClient,
  AuthClient,
  BidsClient,
  ChangeRequestsClient,
  DashboardClient,
  EstimatesClient,
  FeedbackClient,
  FilesClient,
  FlagsClient,
  InspectionsClient,
  InvoicesClient,
  IssuesClient,
  LookupClient,
  ManagementCompaniesClient,
  MessagesClient,
  NotesClient,
  PaymentsClient,
  PropertiesClient,
  ReportingClient,
  SchedulingClient,
  SearchClient,
  StripeClient,
  UserProfileClient,
  UsersClient,
  VendorClient,
  WorkflowClient,
  WorkordersClient,
  ZillowClient,
} from "./clients";
import { makeFileUrls } from "./fileUrls";

export const buildApiClient = (baseUrl: string, axios: AxiosInstance) => {
  const dashboardClient = new DashboardClient(baseUrl, axios);
  const workOrdersClient = new WorkordersClient(baseUrl, axios);
  const usersClient = new UsersClient(baseUrl, axios);
  const propertiesClient = new PropertiesClient(baseUrl, axios);
  const lookupClient = new LookupClient(baseUrl, axios);
  const vendorClient = new VendorClient(baseUrl, axios);
  const authClient = new AuthClient(baseUrl, axios);
  const filesClient = new FilesClient(baseUrl, axios);
  const messagesClient = new MessagesClient(baseUrl, axios);
  const adminClient = new AdminClient(baseUrl, axios);
  const searchClient = new SearchClient(baseUrl, axios);
  const feedbackClient = new FeedbackClient(baseUrl, axios);
  const notesClient = new NotesClient(baseUrl, axios);
  const profileClient = new UserProfileClient(baseUrl, axios);
  const paymentsClient = new PaymentsClient(baseUrl, axios);

  const inspectionClient = new InspectionsClient(baseUrl, axios);
  const issuesClient = new IssuesClient(baseUrl, axios);
  const assignments = new AssignmentsClient(baseUrl, axios);
  const bids = new BidsClient(baseUrl, axios);
  const changeRequests = new ChangeRequestsClient(baseUrl, axios);
  const estimates = new EstimatesClient(baseUrl, axios);
  const invoices = new InvoicesClient(baseUrl, axios);
  const payments = new PaymentsClient(baseUrl, axios);
  const scheduling = new SchedulingClient(baseUrl, axios);
  const workflow = new WorkflowClient(baseUrl, axios);
  const companiesClient = new ManagementCompaniesClient(baseUrl, axios);
  const flagsClient = new FlagsClient(baseUrl, axios);
  const reportsClient = new ReportingClient(baseUrl, axios);
  const stripeClient = new StripeClient(baseUrl, axios);
  const zillowClient = new ZillowClient(baseUrl, axios);

  const fileUrls = makeFileUrls(baseUrl);

  return {
    workOrdersClient,
    dashboardClient,
    inspectionClient,
    issues: {
      main: issuesClient,
      assignments,
      bids,
      changeRequests,
      estimates,
      invoices,
      payments,
      scheduling,
      workflow,
      reports: reportsClient,
    },
    companiesClient,
    flagsClient,
    usersClient,
    propertiesClient,
    lookupClient,
    vendorClient,
    authClient,
    filesClient,
    messagesClient,
    adminClient,
    searchClient,
    feedbackClient,
    notesClient,
    profileClient,
    paymentsClient,
    zillowClient,
    stripeClient,
    fileUrls,
  };
};

export type ApiClientType = ReturnType<typeof buildApiClient>;
