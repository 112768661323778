import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Unstable_Grid2 as Grid
} from '@mui/material';
import React, { useMemo } from 'react';
import { apiProvider } from '../../../shared/api/apiProvider';
import { CenteredLoader } from '../../../components/CenteredLoader';
import { FormDefaults, SetClientConfigurationPayload, VendorClientConfiguration } from '../../../shared/api/clients';
import { rooFmt } from '../../../shared/utils';
import { MuiIcon } from '../../../shared/icons';
import { z } from 'zod';
import { FieldSingleSelect, useRooForm } from '../../../components/form';
import { FieldMuiNumber } from '../../../components/form/FieldMuiNumber';
import { RooDialog } from '../../../components';
import { DropdownOption, useCurrentVendor } from '../../../shared/store';
import { Disclosure, useDisclosure } from '@roo/lib';

export const FormSettingsWidget = ({ companyId }: { companyId: string }) => {
  const { isLoading, data } = useQuery(['vendor', 'clients', 'configuration', companyId], () =>
    apiProvider.vendorClient.getClientConfiguration(companyId)
  );

  if (isLoading) {
    return <CenteredLoader />;
  }

  if (!isLoading && data == null) {
    return <Alert color={'error'}>This company is not your client</Alert>;
  }

  return <FormSettingsDisplay configuration={data} companyId={companyId} />;
};

const FormSettingsDisplay = ({
  configuration,
  companyId
}: {
  configuration: VendorClientConfiguration;
  companyId: string;
}) => {
  const disclosure = useDisclosure(false);
  return (
    <>
      <List
        className={'profile'}
        subheader={
          <Stack direction={'row'}>
            <ListSubheader>
              <MuiIcon.Settings /> Settings
            </ListSubheader>
            <IconButton color={'primary'} onClick={disclosure.open}>
              <MuiIcon.Edit />
            </IconButton>
          </Stack>
        }
      >
        <ListItem>
          <ListItemText
            primary={rooFmt.percentMaybe(configuration.formDefaults.discountPercent, '-')}
            secondary={'Default Discount'}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={configuration.accountManager?.fullName ?? '-'} secondary={'Account Manager'} />
        </ListItem>
        {/*<ListItem>*/}
        {/*  <ListItemText*/}
        {/*    primary={rooFmt.percentMaybe(configuration.formDefaults.taxPercent, '-')}*/}
        {/*    secondary={'Default Tax'}*/}
        {/*  />*/}
        {/*</ListItem>*/}
      </List>
      <UpdateClientConfigurationModal config={configuration} companyId={companyId} disclosure={disclosure} />
    </>
  );
};

const UpdateClientConfigurationModal = ({
  disclosure,
  companyId,
  config
}: {
  disclosure: Disclosure;
  config: VendorClientConfiguration;
  companyId: string;
}) => {
  return (
    <RooDialog onClose={disclosure.close} fullWidth maxWidth={'sm'} open={disclosure.isOpen}>
      <RooDialog.Title onClose={disclosure.close}>Update Client Settings</RooDialog.Title>
      <UpdateClientConfigurationForm disclosure={disclosure} companyId={companyId} config={config} />
    </RooDialog>
  );
};

const ConfigurationSchema = z.object({
  discount: z.number().nullish(),
  accountManagerId: z.string().nullish()
  // tax: z.number().nullish()
});

type ConfigurationForm = z.infer<typeof ConfigurationSchema>;

const UpdateClientConfigurationForm = ({
  disclosure,
  config,
  companyId
}: {
  disclosure: Disclosure;
  config: VendorClientConfiguration;
  companyId: string;
}) => {
  const { control, handleSubmit } = useRooForm(ConfigurationSchema, {
    defaultValues: {
      // tax: formDefaults.taxPercent,
      discount: config?.formDefaults.discountPercent,
      accountManagerId: config?.accountManager?.id
    }
  });

  const vendor = useCurrentVendor();
  const handlerOptions = useMemo(
    () =>
      vendor.workingUsers.map((x) => ({
        label: x.fullName,
        value: x.id
      })) satisfies DropdownOption<string>[],
    [vendor.users]
  );

  const qc = useQueryClient();

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (values: ConfigurationForm) =>
      apiProvider.vendorClient.setClientConfiguration(
        new SetClientConfigurationPayload({
          companyId: companyId,
          accountManagerId: values.accountManagerId,
          formDefaults: new FormDefaults({
            discountPercent: values.discount,
            taxPercent: null //values.tax
          })
        })
      ),
    onSuccess: async () => {
      await qc.invalidateQueries(['vendor', 'clients']);
      disclosure.close();
    }
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
        <RooDialog.Content>
          <Grid xs={12}>
            <FieldMuiNumber control={control} name={'discount'} label="Default Discount %" fullWidth margin="normal" />
            <FieldSingleSelect
              control={control}
              name={'accountManagerId'}
              label={'Account Manager'}
              options={handlerOptions}
            />
            {/*<FieldMuiNumber control={control} name={'tax'} label="Default Tax %" fullWidth margin="normal" />*/}
          </Grid>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            <Button type="submit" disabled={isLoading}>
              Update
            </Button>
            <Button color={'muted'} disabled={isLoading} variant={'contained'} onClick={disclosure.close}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </form>
    </>
  );
};
