import { Badge, Box, Button, Chip, Divider, IconButton, Menu, Tooltip, Typography } from '@mui/material';
import MuiToolbar from '@mui/material/Toolbar';
import { RooAvatar, SearchBar } from 'components';
import { useChatStore } from 'components/Chat/chatStore';
import { ReactNode, useState } from 'react';
import { CapabilityType, RooPermission } from 'shared/api/clients';
import { Routes } from 'shared/routing';
import {
  AuthManager,
  useAppStore,
  useCurrentUser,
  useCurrentVendor,
  useHasCapability,
  useHasPermission,
  useIsAdmin
} from 'shared/store';
import { rooFmt, useIsSmallScreen, WithReqChildren } from 'shared/utils';
import { AppBar, MenuPaperStyles, RouteMenuItem, ToolbarMenuItem } from './shared';
import { MuiIcon } from '../shared/icons';
import { useRequestGlobalModal, useWatchGlobalModal } from '../components/modals';
import { Center } from '../components/Center';

export const RooToolbar = () => {
  const { isVisible, toggle } = useWatchGlobalModal('appSidebar');
  const isMobile = useIsSmallScreen();
  const user = useAppStore((x) => x.userDetails);
  const breadcrumbTitle = useAppStore((x) => x.breadcrumbTitle);

  return (
    <AppBar position="fixed" open={isVisible} id={'header-anchor'}>
      <MuiToolbar sx={{ pl: { xs: 2, lg: 3 }, pr: { xs: 1, lg: 3 } }} className="justify-content-between">
        {(isMobile || !isVisible) && (
          <IconButton edge="start" color="inherit" aria-label="open drawer 1" onClick={toggle} sx={{ mr: 2 }}>
            <MuiIcon.Menu />
          </IconButton>
        )}

        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
          <Typography variant="h6" color="inherit">
            {breadcrumbTitle}{' '}
            <Chip label="beta" color="primary" size={'small'} sx={{ height: 18, fontSize: 12, fontWeight: 500 }} />
          </Typography>
        </Box>
        <Box className="d-flex align-items-center">
          <Box className="topbar-buttons">
            <Center>
              <SearchBar />
              <ChatButton />
              <HelpMenu />
              <StopImpersonationButton />
            </Center>
          </Box>
          <Box className="topbar-user-name" sx={{ display: { xs: 'none', lg: 'block' } }}>
            <div className="d-flex align-items-center">
              <div style={{ borderLeft: '1px solid #ccc', height: 30, margin: '0 1em' }} />
              <span style={{ fontSize: 16, color: 'black', marginRight: '.7em' }}>{rooFmt.abbrevName(user)}</span>
            </div>
          </Box>
          <ProfileMenu />
        </Box>
      </MuiToolbar>
    </AppBar>
  );
};

const ProfileMenu = () => {
  const [, toggleInviteManager] = useRequestGlobalModal('inviteUser', {
    props: { capability: CapabilityType.PropertyManager }
  });

  const [, toggleAddVendorMember] = useRequestGlobalModal('addVendorMember');
  const [, toggleGoToIssueModal] = useRequestGlobalModal('goToIssueModal');
  const user = useAppStore((x) => x.userDetails);
  const currentUser = useCurrentUser();
  const currentVendor = useCurrentVendor();
  const isAdmin = useIsAdmin();
  const canInviteManager = useHasCapability(CapabilityType.ManagementCompanyOwner, currentUser.managementCompany?.id);
  const canAddMember = currentVendor != null;

  const company = currentUser.managementCompany;
  const isCompanyOwner = useHasCapability(CapabilityType.ManagementCompanyOwner, company?.id);
  const isCompanyManager = useHasCapability(CapabilityType.PropertyManager, company?.id);
  const isManager = isCompanyManager || isCompanyOwner;

  return (
    <ExpandoMenu
      anchor={(handleClick) => (
        <Tooltip title="Menu">
          <IconButton onClick={handleClick}>
            <RooAvatar
              sx={{ border: '3px solid white', boxShadow: '0 1px 1px 1px #ccc' }}
              name={user.fullName}
              avatarUrl={user.avatarUrl}
              size={'l'}
            />
          </IconButton>
        </Tooltip>
      )}
    >
      <RouteMenuItem icon={MuiIcon.Person} to={Routes.CurrentUserProfile}>
        My Profile
      </RouteMenuItem>
      {isManager && (
        <RouteMenuItem
          icon={MuiIcon.LocationCity}
          to={{ pathname: Routes.CompanyProfile, params: { companyId: company.id } }}
        >
          Company Profile
        </RouteMenuItem>
      )}

      {currentVendor != null && (
        <RouteMenuItem
          icon={MuiIcon.Engineering}
          to={{ pathname: Routes.VendorProfile, params: { vendorId: currentVendor.id } }}
        >
          Vendor Profile
        </RouteMenuItem>
      )}

      {canInviteManager && (
        <ToolbarMenuItem onClick={toggleInviteManager} icon={MuiIcon.PersonAdd}>
          Invite
        </ToolbarMenuItem>
      )}
      {canAddMember && (
        <ToolbarMenuItem onClick={toggleAddVendorMember} icon={MuiIcon.PersonAdd}>
          Invite Coworker
        </ToolbarMenuItem>
      )}
      <Divider />
      {isAdmin && [
        <ToolbarMenuItem key={'go-to-issue'} onClick={toggleGoToIssueModal} icon={MuiIcon.MoveUp}>
          Go to Issue
        </ToolbarMenuItem>,
        <RouteMenuItem key={Routes.AdminAccountsList} icon={MuiIcon.Group} to={Routes.AdminAccountsList}>
          Accounts
        </RouteMenuItem>,
        <RouteMenuItem key={Routes.AdminCompanyList} icon={MuiIcon.LocationCity} to={Routes.AdminCompanyList}>
          Companies
        </RouteMenuItem>,
        <Divider key={'divider'} />
      ]}

      <ToolbarMenuItem onClick={() => AuthManager.instance.logOut(false)} icon={MuiIcon.Logout}>
        Logout
      </ToolbarMenuItem>
    </ExpandoMenu>
  );
};

const StopImpersonationButton = () => {
  const isMobile = useIsSmallScreen();
  if (!AuthManager.instance.isImpersonating()) {
    return null;
  }

  if (!isMobile) {
    return (
      <Button
        onClick={() => AuthManager.instance.stopImpersonation()}
        variant={'contained'}
        size={'small'}
        color={'muted'}
        startIcon={<MuiIcon.PersonOff />}
      >
        Stop Impersonating
      </Button>
    );
  }

  return (
    <Tooltip title={'Stop Impersonating'}>
      <IconButton onClick={() => AuthManager.instance.stopImpersonation()}>
        <MuiIcon.PersonOff />
      </IconButton>
    </Tooltip>
  );
};

const HelpMenu = () => {
  return (
    <ExpandoMenu
      anchor={(handleClick) => (
        <Tooltip title="About Us">
          <IconButton onClick={handleClick}>
            <MuiIcon.HelpOutline />
          </IconButton>
        </Tooltip>
      )}
    >
      <RouteMenuItem icon={MuiIcon.Gavel} to={Routes.AboutUsLegal}>
        Legal
      </RouteMenuItem>
    </ExpandoMenu>
  );
};

const ChatButton = () => {
  const unreadMessages = useChatStore((x) => x.unreadCount);
  const [, toggleChat] = useRequestGlobalModal('chatDrawer');

  return (
    <>
      <IconButton color="inherit" onClick={toggleChat}>
        <Badge color="error" badgeContent={unreadMessages}>
          <MuiIcon.Mail />
        </Badge>
      </IconButton>
    </>
  );
};

const ExpandoMenu = ({
  anchor,
  children
}: {
  anchor: (onClick: (event: React.MouseEvent<HTMLElement>) => void) => ReactNode;
} & WithReqChildren) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {anchor(handleClick)}
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={MenuPaperStyles}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {children}
      </Menu>
    </>
  );
};
