import { CapabilityType, Membership, RooPermission, UserRole } from 'shared/api/clients';
import { useAppStore } from 'shared/store/appStore';
import { AuthManager } from './authManager';

export const useIsMultiCompany = () => {
  const memberships = useCurrentMemberships();
  return memberships.length > 1;
};

export const useHasPermission = (permission: RooPermission, companyId?: string) => {
  const memberships = useCurrentMemberships();
  const permissions =
    companyId != null
      ? memberships.find((x) => x.managementCompanyId === companyId)?.permissions ?? []
      : memberships.flatMap((x) => x.permissions);
  return permissions.indexOf(permission) > -1;
};

export const useHasInspectionsAccess = () => {
  const user = useCurrentUser();
  const isGc = useIsGeneralContractor();
  const isPm = useHasCapability(CapabilityType.PropertyManager);
  const hardcodedUsers = [
    '2b2e8ad6-3772-4e19-a864-55db16a5eec5', //cezar+pm
    '46ddcaaf-7f20-49f8-a08b-93ce01b0804c' //cezar+vendor1
  ];
  return isGc || hardcodedUsers.includes(user.id) || isPm;
};

export const useHasCapability = (capability: CapabilityType, companyId: string = null) => {
  const memberships = useCurrentMemberships();
  const capabilities =
    companyId == null
      ? memberships.flatMap((x) => x.capabilities)
      : memberships.find((x) => x.managementCompanyId === companyId)?.capabilities ?? [];
  return capabilities.indexOf(capability) > -1;
};

export const useThemeName = () => {
  const theme = useAppStore((x) => x.theme);
  return theme;
};

export const useCurrentUser = () => {
  return useAppStore((x) => x.userDetails);
};

export const useCompanyContext = () => useAppStore((x) => x.companyContext);

export const useCurrentVendor = () => {
  return useAppStore((x) => x.vendorInfo);
};

export const useIsGeneralContractor = () => {
  const currentVendor = useCurrentVendor();
  const isGc = currentVendor?.isGeneralContractor ?? false;
  return isGc;
};

export const useIsSpecialUser = () => {
  const isGc = useIsGeneralContractor();
  const isImpersonated = AuthManager.instance.isImpersonating();

  return isGc || isImpersonated;
};

export const useCurrentMemberships = () => {
  return useAppStore((x) => x.memberships);
};

export const useIsAdmin = () => {
  const user = useCurrentUser();
  const impersonator = useAppStore((x) => x.impersonatingUser);
  const adminRoles = [UserRole.Admin, UserRole.Dev];
  return adminRoles.includes(user.accountRole) || (impersonator != null && impersonator.role === UserRole.Dev);
};

export const useCurrentCanManageOther = (user: {
  memberships?: Membership[];
}): { canManage: boolean; companyId?: string } => {
  const currentMemberships = useCurrentMemberships();
  // invariant: PM can only manage 1 company
  const managerFor = currentMemberships.find(
    (x) =>
      x.capabilities.indexOf(CapabilityType.ManagementCompanyOwner) !== -1 ||
      x.capabilities.indexOf(CapabilityType.PropertyManager) !== -1
  );
  if (managerFor == null || user?.memberships == null) {
    return { canManage: false };
  }

  const userEquiv = user.memberships.find((x) => x.managementCompanyId === managerFor.managementCompanyId);

  return { canManage: userEquiv != null, companyId: userEquiv?.managementCompanyId };
};
