import { Autocomplete, autocompleteClasses, Box, Popper, Stack, styled, TextField, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import React, {
  createContext,
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { Control, useController } from 'react-hook-form';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { FieldSingleSelect, FieldText, FieldTextArea, Required } from 'components/form';
import { ErrorMessage } from 'components/form/ErrorMessage';
import { Routes } from 'shared/routing';
import {
  DropdownOptionWithData,
  priorityOptions,
  PropertyGroup,
  useAppStore,
  usePropertyGroupOptions,
  usePropertyUnitOptions
} from 'shared/store';
import { RouterLink } from 'components';
import { useWindowDimensions } from 'shared/utils';
import { z } from 'zod';
import { MuiIcon } from '../../../shared/icons';
import Grid2 from '@mui/material/Unstable_Grid2';

export const WorkorderSchema = z
  .object({
    id: z.string().nullish(),
    priority: z.number(),
    propertyGroupId: z.string(),
    propertyUnit: z.string().nullish(),
    notes: z.string().nullish(),
    gateCode: z.string().max(255).nullish(),
    lockBox: z.string().max(255).nullish(),
    externalReference: z.string().max(255).nullish(),
    isEditing: z.boolean()
  })
  .superRefine((val, ctx) => {
    if (val.propertyGroupId == null || val.isEditing) {
      return;
    }

    const store = useAppStore.getState();
    const group = store.propertyGroups.find((x) => x.key === val.propertyGroupId);
    if (group == null) {
      ctx.addIssue({
        message: 'Required',
        code: 'custom',
        path: ['propertyGroupId']
      });
      return;
    }

    if (!group.isSingle && val.propertyUnit == null) {
      ctx.addIssue({
        message: 'Required',
        code: 'custom',
        path: ['propertyUnit']
      });
    }
  });

export type WorkorderDefinition = z.infer<typeof WorkorderSchema>;

export const WorkorderForm = ({
  isEditing,
  control,
  watch
}: {
  isEditing: boolean;
  control: Control<WorkorderDefinition>;
  watch: UseFormWatch<WorkorderDefinition>;
}) => {
  const propertyGroups = useAppStore((x) => x.propertyGroups);
  const selectedGroupId = watch('propertyGroupId');
  const selectedGroup = propertyGroups.find((x) => x.key === selectedGroupId && selectedGroupId != null);
  const needsUnits = selectedGroup != null && selectedGroup.children.length > 1 && !isEditing;
  const unitOptions = usePropertyUnitOptions().filter((x) =>
    selectedGroup == null ? false : selectedGroup.children.map((y) => y.id).indexOf(x.value) > -1
  );

  return (
    <Grid2 container spacing={2}>
      <Grid2 xl={needsUnits ? 3 : 6} xs={12}>
        <FieldSingleSelect control={control} options={priorityOptions} required name={'priority'} label={'Priority'} />
      </Grid2>
      <Grid2 xl={6} xs={12}>
        <Stack direction={'row'} spacing={2}>
          <PropertySelect control={control} isEditing={isEditing} />
          {!isEditing && (
            <RouterLink
              style={{ marginTop: '32px' }}
              to={{ pathname: Routes.PropertyCreate }}
              className="btn btn-secondary"
              role="button"
            >
              New
            </RouterLink>
          )}
        </Stack>
      </Grid2>
      {needsUnits && (
        <Grid2 xl={3} xs={12}>
          <FieldSingleSelect
            control={control}
            isDisabled={isEditing}
            options={unitOptions}
            name={'propertyUnit'}
            required
            label={'Address 2'}
          />
        </Grid2>
      )}
      <Grid2 xl={4} xs={12}>
        <FieldText control={control} name={'gateCode'} label={'Gate Code'} />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldText control={control} name={'lockBox'} label={'Lock Box'} />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldText control={control} name={'externalReference'} label={'Ref #'} />
      </Grid2>
      <Grid2 xl={12} xs={12}>
        <FieldTextArea control={control} name={'notes'} label={'Entry Notes'} />
      </Grid2>
    </Grid2>
  );
};
const filterOptions = createFilterOptions({
  stringify: (opt: DropdownOptionWithData<string, PropertyGroup>) => {
    const address = opt.data.mainProperty?.address;
    const str = [
      opt.data.mainProperty?.displayAddress,
      address?.city,
      address?.state,
      address?.zipCode,
      opt.data.display
    ].join(' ');
    return str;
  }
});
const PropertySelect = ({ isEditing, control }: { isEditing: boolean; control: Control<WorkorderDefinition> }) => {
  const propertyGroupOptions = usePropertyGroupOptions();
  const {
    field,
    fieldState: { error }
  } = useController({
    control,
    name: 'propertyGroupId'
  });

  const val = useMemo(
    () => propertyGroupOptions.find((x) => x.value === field.value),
    [field.value, propertyGroupOptions]
  );

  return (
    <FormGroup style={{ flexGrow: 1 }}>
      <label className={'form-label'}>
        <Required text={'Property'} />
      </label>
      <Autocomplete
        className={error != null ? 'is-invalid' : ''}
        value={val}
        onChange={(_, value) => field.onChange(value?.value)}
        disabled={isEditing}
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListBoxComponent as any}
        renderInput={(params) => <TextField {...params} size={'small'} label="Choose a property" />}
        renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
        options={propertyGroupOptions}
        filterOptions={filterOptions}
        isOptionEqualToValue={(a, b) => {
          return a?.value === b?.value && a != null && b != null;
        }}
      />
      <ErrorMessage error={error} />
    </FormGroup>
  );
};

type SelectOption = [HTMLAttributes<HTMLLIElement>, DropdownOptionWithData<string, PropertyGroup>];
type SelectRowProps = Omit<ListChildComponentProps, 'data'> & {
  data: SelectOption[];
};

const LISTBOX_PADDING = 8; // px

const PropertySelectRow = ({ data, index, style }: SelectRowProps) => {
  console.log({ data, index, style });
  const dataSet = data[index];
  const group = dataSet[1].data;
  const { height, ...styleRest } = style;
  const inlineStyle: CSSProperties = {
    ...styleRest,
    top: (style.top as number) + LISTBOX_PADDING,
    alignItems: 'flex-start',
    flexDirection: 'column'
  };

  const { setSize, windowWidth, sizeMap, listRef } = useContext(OuterElementContext);
  const root = useRef();
  useEffect(() => {
    const height = (root.current as any).getBoundingClientRect().height;
    const currentSize = sizeMap.current[index];
    if (currentSize == null || currentSize !== height) {
      setSize(index, height);
      listRef.current.resetAfterIndex(index);
    }
    //eslint-disable-next-line
  }, [windowWidth]);

  const address = group.mainProperty?.address;

  return (
    <Box ref={root} component={'li'} style={inlineStyle} {...dataSet[0]}>
      <Box>
        <strong>{group.display}</strong>
      </Box>
      <Box>
        {group.mainProperty != null && (
          <>
            <Typography>
              <MuiIcon.House /> {address.address1} {address.address2}
            </Typography>
            <Typography sx={{ marginLeft: '30px' }}>
              {address.city}, {address.state} {address.zipCode}
            </Typography>
          </>
        )}
        {group.mainProperty == null && (
          <>
            <MuiIcon.LocationCity /> <span>{group.children.length} units</span>
          </>
        )}
      </Box>
    </Box>
  );
};

const OuterElementContext = createContext<{
  other: Omit<ListBoxComponentProps, 'children'>;
  setSize: (index: number, size: number) => void;
  windowWidth: number;
  sizeMap: MutableRefObject<Record<number, number>>;
  listRef: MutableRefObject<VariableSizeList>;
}>(null);

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const { other } = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...other} />;
});

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

type ListBoxComponentProps = Omit<HTMLAttributes<HTMLElement>, 'children'> & {
  children: SelectOption[];
};

const ListBoxComponent = forwardRef<HTMLDivElement, ListBoxComponentProps>(({ children, ...other }, ref) => {
  const itemCount = children.length;
  const itemSize = 80;
  const { width: windowWidth } = useWindowDimensions();

  const sizeMap = useRef<Record<number, number>>({});
  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current[index] = size;
  }, []);
  const getSize = useCallback((index: number) => sizeMap.current[index] || itemSize, []);

  const getHeight = useCallback(() => {
    let toCount = children;
    if (toCount.length > 8) {
      toCount = children.slice(0, 7);
    }

    return toCount.map((_, i) => getSize(i)).reduce((a, b) => a + b, 0);
  }, [children, getSize]);

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={{ other, setSize, windowWidth, sizeMap, listRef: gridRef }}>
        <VariableSizeList
          itemData={children}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={getSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {PropertySelectRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
});
