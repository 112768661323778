import { useEffect, useState } from 'react';
import { Alert, Card, Col, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { apiProvider } from 'shared/api/apiProvider';
import { CreateWorkorderPayload, IProperty, Priority } from 'shared/api/clients';
import { useRooForm } from 'components/form/utils';
import { redirect, Routes } from 'shared/routing';
import { getPropertyFromGroup, useAppStore } from 'shared/store';
import { NavSlim, PageContentSection, PropertyCard, SaveButton, UserCard } from 'components';
import { showSuccess } from 'shared/utils';
import { Contact } from './shared/types';
import { WorkorderDefinition, WorkorderForm, WorkorderSchema } from './shared/WorkorderForm';
import { Stack } from '@mui/material';

export const CreateWorkorder = () => {
  const { properties, userDetails, propertyGroups } = useAppStore();

  const location = useLocation<{ property?: IProperty }>();
  const property = location?.state?.property;

  const contacts: Contact[] = [
    {
      ...userDetails,
      role: 'Property Manager'
    }
  ];

  const [selectedProp, setSelectedProp] = useState<IProperty>(null);

  const { handleSubmit, watch, control } = useRooForm(WorkorderSchema, {
    defaultValues: {
      priority: Priority.Medium,
      isEditing: false,
      propertyGroupId: property?.name,
      propertyUnit: property?.id
    }
  });

  const [selectedGroupId, propertyUnit] = watch(['propertyGroupId', 'propertyUnit']);

  useEffect(() => {
    let prop = getPropertyFromGroup(propertyGroups, selectedGroupId, propertyUnit);
    setSelectedProp(prop);
  }, [selectedGroupId, propertyUnit, setSelectedProp, properties, propertyGroups]);

  const submit = async (values: WorkorderDefinition) => {
    let prop = getPropertyFromGroup(propertyGroups, values.propertyGroupId, values.propertyUnit);
    try {
      const newOrder = await apiProvider.workOrdersClient.create(
        new CreateWorkorderPayload({
          propertyId: prop.id,
          notes: values.notes,
          gateCode: values.gateCode,
          lockBox: values.lockBox,
          externalReference: values.externalReference,
          priority: values.priority,
          managementCompanyId: userDetails.managementCompany.id,
          inspectionId: null
        })
      );

      redirect({ pathname: Routes.WorkorderView, params: { id: newOrder.id } });
      showSuccess();
    } catch (err) {}
  };

  return (
    <>
      <Helmet>
        <title>Create Workorder - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'New Workorder' }]} />
      <PageContentSection>
        <Row>
          <Col xl={3}>
            <Card>
              <Card.Body>
                <h4>New Workorder</h4>

                <strong>Property</strong>
                {selectedProp && <PropertyCard property={selectedProp} />}
                {!selectedProp && <Alert variant={'primary'}>Select a property to see more details</Alert>}

                <dl>
                  <dt>Company</dt>
                  <dd>{userDetails.managementCompany.name}</dd>
                </dl>
                <strong>Key Contacts</strong>
                {contacts.map((contact) => {
                  return <UserCard style={{ marginTop: '10px' }} key={contact.id} user={contact} role={contact.role} />;
                })}
              </Card.Body>
            </Card>
          </Col>
          <Col xl={9}>
            <Row>
              <Col xl={12}>
                <Card>
                  <Card.Body>
                    <Form noValidate onSubmit={handleSubmit(submit)}>
                      <Stack spacing={2}>
                        <WorkorderForm watch={watch} control={control} isEditing={false} />
                        <Stack alignItems={'flex-end'}>
                          <SaveButton control={control}>Save</SaveButton>
                        </Stack>
                      </Stack>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageContentSection>
    </>
  );
};
