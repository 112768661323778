import { Column } from 'primereact/column';
import { Property } from '../../../shared/api/clients';
import { PropertyCard, RooDialog, RouterLink } from '../../../components';
import { Routes } from '../../../shared/routing';
import { rooFmt } from '../../../shared/utils';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import React, { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { InspectionDefinition } from './CreateInspectionModal';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MuiIcon } from 'shared/icons';
import { useAppStore } from '../../../shared/store';
import { Disclosure, useDisclosure } from '@roo/lib';

export const PropertyField = ({ control }: { control: Control<InspectionDefinition> }) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control,
    name: 'property'
  });

  const disclosure = useDisclosure(false);

  let display = (
    <Button sx={{ minWidth: 200 }} onClick={disclosure.open} size={'small'} startIcon={<MuiIcon.Search />}>
      Select Property
    </Button>
  );
  if (field.value != null) {
    display = <PropertyCard property={field.value} />;
  }

  return (
    <>
      <Stack gap={1}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} fontWeight={600}>
            Property
          </Typography>
          {field.value != null && (
            <Button size={'small'} variant={'text'} onClick={() => field.onChange(null)}>
              Change
            </Button>
          )}
        </Stack>

        <Box px={1}>{display}</Box>
        {error != null && <Typography color={'error'}>{error.message}</Typography>}
      </Stack>
      <PropertySelectionModal
        disclosure={disclosure}
        onSelect={(val) => {
          // @ts-ignore
          field.onChange(val);
          disclosure.close();
        }}
      />
    </>
  );
};

export const PropertySelectionModal = ({
  disclosure,
  onSelect
}: {
  disclosure: Disclosure;
  onSelect: (val: Property) => void;
}) => {
  return (
    <RooDialog open={disclosure.isOpen} onClose={disclosure.close} maxWidth={'xl'} fullWidth>
      <RooDialog.Title onClose={disclosure.close}>Select Property</RooDialog.Title>
      <ModalBody disclosure={disclosure} onSelect={onSelect} />
    </RooDialog>
  );
};

export const ModalBody = ({ disclosure, onSelect }: { disclosure: Disclosure; onSelect: (val: Property) => void }) => {
  const properties = useAppStore((x) => x.properties);
  const [filters] = useState({
    name: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.address1': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.address2': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.city': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.state': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'manager.fullName': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'owner.fullName': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    isOccupied: { value: null, matchMode: 'equals' },
    isActive: { value: true, matchMode: 'equals' }
  });
  return (
    <PrimeDataTable filters={filters as any} paginator rows={10} value={properties} dataKey={'id'}>
      <Column
        sortable
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        field={'name'}
        header={'Name'}
        body={(row: Property) => (
          <RouterLink to={{ pathname: Routes.PropertyView, params: { id: row.id } }}>{row.name}</RouterLink>
        )}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        header={'Address 1'}
        field={'address.address1'}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        header={'Address 2'}
        field={'address.address2'}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        header={'City'}
        field={'address.city'}
        style={{ width: 200 }}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        header={'State'}
        field={'address.state'}
        style={{ width: 150 }}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        field={'manager.fullName'}
        header={'PM'}
        body={(row: Property) => row.manager?.fullName ?? row.managementCompany?.name}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        field={'owner.fullName'}
        header={'Owner'}
        body={(row: Property) => row.owner?.fullName ?? 'N/A'}
      />
      <Column
        showFilterMatchModes={false}
        sortable
        header={'Maint. limit'}
        field={'maintenanceLimit'}
        dataType="numeric"
        body={(row: Property) => rooFmt.moneyMaybe(row.maintenanceLimit, 'N/A')}
      />
      <Column
        header={'Actions'}
        body={(row: Property) => <Button onClick={() => onSelect(row)}>Choose</Button>}
        style={{ width: 110 }}
      />
    </PrimeDataTable>
  );
};
