import { Property } from 'shared/api/clients';

export type PropertyGroup = {
  key: string;
  display: string;
  mainProperty: Property;
  children: Property[];
  isSingle: boolean;
};

export const getPropertyFromGroup = (groups: PropertyGroup[], groupKey: string, unitKey: string) => {
  const group = groups.find((x) => x.key === groupKey);
  if (group == null) {
    return null;
  }

  if (group.isSingle) {
    return group.children[0];
  }

  if (!group.isSingle && unitKey == null) {
    return null;
  }

  return group.children.find((x) => x.id === unitKey);
};

export const buildPropertyGroups = (properties: Property[]): PropertyGroup[] => {
  const groups: PropertyGroup[] = [];
  for (const prop of properties) {
    if (!prop.isActive) {
      continue;
    }
    const propKey = prop.name;
    let existing = groups.find((x) => x.key === propKey);
    if (existing == null) {
      existing = {
        key: propKey,
        display: prop.name,
        children: [],
        isSingle: true,
        mainProperty: prop
      };
      groups.push(existing);
    }

    existing.children.push(prop);
    existing.isSingle = existing.children.length === 1;
    if (!existing.isSingle) {
      existing.mainProperty = null;
    }
  }

  return groups;
};
