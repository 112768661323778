import { Stack } from '@mui/material';
import { AddIssueNoticeButton } from 'pages/Admin/CompanyList/AddIssueNoticeButton';
import { CreateCompanyButton } from 'pages/Admin/CompanyList/CreateCompanyButton';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Helmet } from 'react-helmet';
import { ManagementCompanyDetails } from 'shared/api/clients';
import { ContentWrapper, NavSlim, RouterIconButton } from 'components';
import { rooFmt } from 'shared/utils';
import { useAdminAllCompanies } from '../../../shared/api/queries';
import { Routes } from '../../../shared/routing';
import { MuiIcon } from '../../../shared/icons';
import React from 'react';

export const CompanyList = () => {
  const { isLoading, data: companies } = useAdminAllCompanies();

  return (
    <>
      <Helmet>
        <title>Company List - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Company List' }]} />
      <ContentWrapper>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          p={4}
          sx={{ backgroundColor: 'white' }}
        >
          <h5 className="m-0">Company List</h5>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
            <CreateCompanyButton />
          </Stack>
        </Stack>
        <DataTable
          tableStyle={{ width: '100%' }}
          value={companies}
          loading={isLoading}
          paginator
          rows={12}
          sortField={'createdAt'}
          sortOrder={-1}
        >
          <Column field={'name'} header={'Name'} sortable filter />
          <Column field={'properties'} header={'# Properties'} sortable />
          <Column field={'issues'} header={'# Issues'} sortable />
          <Column field={'propertyOwners'} header={'# Prop. Owners'} sortable />
          <Column field={'tenants'} header={'# Tenants'} sortable />
          <Column field={'managers'} header={'# Managers'} sortable />
          <Column field={'vendors'} header={'# Vendors'} sortable />
          <Column
            field={'createdAt'}
            header={'Created'}
            sortable
            body={(row: ManagementCompanyDetails) => rooFmt.date(row.createdAt)}
          />
          <Column
            field={'mutedNotifications'}
            header={'Muted Notifications'}
            sortable
            body={(row: ManagementCompanyDetails) => (row.mutedNotifications ? 'Yes' : 'No')}
          />
          <Column
            headerStyle={{ width: '220px' }}
            body={(row: ManagementCompanyDetails) => (
              <>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                  <AddIssueNoticeButton key={`${row.id}-notice`} companyId={row.id} currentNotice={row.issueNotice} />
                </Stack>
              </>
            )}
          />
          <Column
            headerStyle={{ width: '80px' }}
            body={(row: ManagementCompanyDetails) => (
              <Stack>
                <RouterIconButton
                  color={'primary'}
                  target={'_blank'}
                  to={{ pathname: Routes.CompanyProfile, params: { companyId: row.id } }}
                >
                  <MuiIcon.Visibility />
                </RouterIconButton>
                {/*<DeleteCompanyButton company={row} />*/}
              </Stack>
            )}
          />
        </DataTable>
      </ContentWrapper>
    </>
  );
};
