import { Box, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ContentWrapper, NavSlim } from 'components';
import 'pages/Dashboard/dashboard.scss';
import { ConditionalIssuesArea } from 'pages/Dashboard/IssuesArea';
import { NewMessagesCard } from 'pages/Dashboard/Messages';
import { GlobalSummaryCard, NewStatsArea } from 'pages/Dashboard/Stats';
import { WeeklyScheduleCard } from 'pages/Dashboard/WeeklySchedule';
import { ConditionalWorkordersArea } from 'pages/Dashboard/WorkordersArea';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import { CapabilityType, DescribedDashboardStat, TasksForUsersFilter } from 'shared/api/clients';
import { useCurrentUser, useHasCapability, useIsGeneralContractor } from 'shared/store';
import { PMChart } from './pmChart';
import { VendorsWithOpenIssues } from './VendorsWithOpenIssues';
import { ActionsHeader } from './ActionsHeader';
import { useMyVendors } from '../../shared/api/queries';
import { CenteredLoader } from '../../components/CenteredLoader';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';
import { DashboardContext } from './utils';

export const DashboardPage = () => {
  const currentUser = useCurrentUser();
  const [filters, setFilters] = useLocalStorage({
    key: `dashboard-filters-${currentUser?.id}`,
    getInitialValueInEffect: false,
    defaultValue: {
      vendorIds: [],
      handlerId: null,
      city: null,
      stateCode: null,
      companyId: null
    }
  });

  const [state, setState] = useState({
    stats: [] as DescribedDashboardStat[],
    loading: true,
    forStat: null as DescribedDashboardStat,
    statIsLoading: false
  });

  useEffect(() => {
    const pullOrders = async () => {
      setState((x) => ({
        ...x,
        stats: [],
        loading: true
      }));
      const orders = await apiProvider.workOrdersClient.getDashboardStatsNew(
        new TasksForUsersFilter({
          companyIds: filters.companyId == null ? null : [filters.companyId],
          issueTitle: null,
          workorderId: null,
          quickSearchQuery: null,
          address1: null,
          address2: null,
          createdAt: null,
          friendlyId: null,
          isActive: null,
          isOccupied: null,
          modifiedAt: null,
          primaryPm: null,
          propertyId: null,
          workorderStatus: null,
          issueStatus: null,
          endDate: null,
          startDate: null,
          vendorIds: filters.vendorIds,
          stateCodes: filters.stateCode == null ? null : [filters.stateCode],
          city: filters.city,
          accountManagerId: filters.handlerId,
          onlyTopLevelIssues: null,
          subVendorIds: null
        })
      );
      setState((x) => ({
        ...x,
        stats: orders,
        loading: false
      }));
    };

    void pullOrders();
  }, [filters.companyId, filters.vendorIds, filters.city, filters.stateCode, filters.handlerId]);

  const setStat = useCallback(
    (stat: DescribedDashboardStat, isLoading: boolean) =>
      setState((x) => ({ ...x, forStat: stat, statIsLoading: isLoading })),
    []
  );

  const setIsLoading = useCallback((isLoading: boolean) => setState((x) => ({ ...x, statIsLoading: isLoading })), []);
  const isGc = useIsGeneralContractor();
  const isManager = useHasCapability(CapabilityType.PropertyManager);
  const isManagerType = isGc || isManager;

  return (
    <>
      <DashboardContext.Provider
        value={{ setStat: setStat, statIsLoading: state.statIsLoading, currentStat: state.forStat, setIsLoading }}
      >
        <Helmet>
          <title>Dashboard - Walkthroo</title>
        </Helmet>
        <NavSlim breadcrumbs={[{ text: 'Dashboard' }]} />
        <ContentWrapper className="dashboard">
          <Stack spacing={4} px={{ xs: 2, md: 4 }}>
            {(!isManager || isGc) && <ActionsHeader filters={filters} setFilters={setFilters} />}

            <WeeklyScheduleCard
              companyId={filters.companyId}
              vendorIds={filters.vendorIds}
              stateCode={filters.stateCode}
              city={filters.city}
            />
            {isGc && (
              <Grid2 container columnSpacing={2}>
                <Grid2 xs={12} md={6}>
                  <GlobalStats stats={state.stats} isLoading={state.loading} />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <Messages companyId={filters.companyId} city={filters.city} stateCode={filters.stateCode} />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <VendorsWithOpenIssues
                    companyId={filters.companyId}
                    vendorIds={filters.vendorIds}
                    city={filters.city}
                    stateCode={filters.stateCode}
                  />
                </Grid2>
                {isGc && (
                  <Grid2 xs={12} md={6}>
                    <PMChart
                      companyId={filters.companyId}
                      vendorIds={filters.vendorIds}
                      city={filters.city}
                      stateCode={filters.stateCode}
                    />
                  </Grid2>
                )}
              </Grid2>
            )}

            <NewStatsArea stats={state.stats} loading={state.loading} />
            {isManagerType && (
              <ConditionalWorkordersArea
                companyId={filters.companyId}
                stat={state.forStat}
                vendorIds={filters.vendorIds}
                city={filters.city}
                stateCode={filters.stateCode}
                accountManagerId={filters.handlerId}
              />
            )}
            {!isManagerType && (
              <ConditionalIssuesArea
                companyId={filters.companyId}
                stat={state.forStat}
                vendorIds={filters.vendorIds}
                city={filters.city}
                stateCode={filters.stateCode}
                accountManagerId={filters.handlerId}
              />
            )}
          </Stack>
        </ContentWrapper>
      </DashboardContext.Provider>
    </>
  );
};

const GlobalStats = ({ stats, isLoading }: { stats: DescribedDashboardStat[]; isLoading: boolean }) => {
  return (
    <Box>
      <GlobalSummaryCard stats={stats} loading={isLoading} />
    </Box>
  );
};

const Messages = ({ companyId, stateCode, city }: { companyId: string; stateCode: string; city: string }) => {
  const { data, isLoading } = useMyVendors();
  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <Box>
      <h3 style={{ fontWeight: 400 }}>New Messages</h3>
      <NewMessagesCard companyId={companyId} vendors={data} stateCode={stateCode} city={city} />
    </Box>
  );
};
