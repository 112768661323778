import { RooIcon } from 'shared/icons';
import { Card } from 'react-bootstrap';
import { CapabilityType, IAddress, IProperty, ZillowInfo } from 'shared/api/clients';
import { useCurrentUser, useHasCapability } from 'shared/store';
import { classNames, rooGeo } from 'shared/utils';
import { Routes } from 'shared/routing';
import { rooFmt } from 'shared/utils';
import { IconLink } from 'components/Links';
import { UserLink } from 'components/UserLink';
import { useEffect, useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { Box, Typography } from '@mui/material';
import zillowLogo from 'images/ZillowLogo.png';
import { FileThumbnail } from './Files/FileThumbnail';
import { MapButton } from './MapButton';

export const PropertyCard = ({
  property,
  className,
  showZillow
}: {
  property: IProperty;
  className?: string;
  showZillow?: boolean;
}) => {
  const currentUser = useCurrentUser();
  const isPropertyManager =
    useHasCapability(CapabilityType.PropertyManager, property.managementCompany?.id) ||
    property.createdBy?.id === currentUser.id;
  const isPropertyOwner = useHasCapability(CapabilityType.PropertyOwner, property.managementCompany?.id);
  const isTenant = useHasCapability(CapabilityType.Tenant, property.managementCompany?.id);

  const [zillowInfo, setZillowInfo] = useState<ZillowInfo>();

  useEffect(() => {
    var getData = async () => {
      var data = await apiProvider.zillowClient.getInfoForProperty(property.id);
      setZillowInfo(data);
    };
    if (showZillow) void getData();
  }, [showZillow, property.id]);

  return (
    <>
      <Card style={{ marginTop: '10px' }} className={classNames('property-card', className)}>
        <Card.Body>
          <div className={'property-title'}>
            <strong>
              <IconLink to={{ pathname: Routes.PropertyView, params: { id: property.id } }} text={property.name} />{' '}
            </strong>
          </div>
          {property.coverPhoto != null && (
            <Box my={2}>
              <FileThumbnail file={property.coverPhoto} size={128} />
            </Box>
          )}

          <MapButton propAddress={property.address} />
          {property.name !== property.address.address1 && (
            <div className={'property-address1'}>{property.address.address1}</div>
          )}
          {property.address.address2 && <div className={'property-address2'}>{property.address.address2}</div>}
          <div className={'property-postal'}>
            {property.address.city}, {property.address.state} {property.address.zipCode}
          </div>
          {property.owner && (isPropertyManager || isPropertyOwner) && (
            <div className={'property-owner'}>
              <p style={{ marginBottom: '0', fontSize: '14px' }}>Owned by:</p>
              <UserLink user={property.owner} role={null} />
            </div>
          )}

          <div className={'property-details'}>
            <p className={'mb-0'}>
              {property.isOccupied && <strong>Currently occupied</strong>}
              {!property.isOccupied && <strong>Currently not occupied</strong>}
            </p>
            {!isTenant && (
              <>
                <strong>Maintenace Limit</strong>
                <p className={'mb-0'}>{rooFmt.money(property.maintenanceLimit)}</p>
              </>
            )}
          </div>
          <span className={'type-indicator'}>
            <RooIcon icon={['fas', 'home']} className={'type-indicator'} />
          </span>
        </Card.Body>
      </Card>
      {zillowInfo && (
        <Card className={classNames('property-card', className)}>
          <Card.Body>
            <div style={{ clear: 'both' }}>
              <img src={zillowLogo} width="150" />
            </div>
            {zillowInfo.bedrooms > 0 && <span>{zillowInfo.bedrooms} bd | </span>}
            {zillowInfo.bathrooms > 0 && <span>{zillowInfo.bathrooms} ba | </span>}
            {zillowInfo.livableAreaSquareFeet > 0 && <span>{zillowInfo.livableAreaSquareFeet} sqft</span>}
            <br />
            Zestimate: <strong>{rooFmt.money(zillowInfo.zestimate).replace(/\D00(?=\D*$)/, '')}</strong>
            <br />
            More info at:{' '}
            <a href={zillowInfo.zillowUrl} target="_blank" rel="noreferrer">
              Zillow
            </a>
            <Typography fontSize={10}>This data is provided "as is" via the Zestimate API</Typography>
            <Typography fontSize={8}>
              © Zillow, Inc., 2006-2023. Use is subject to{' '}
              <a href="https://www.zillow.com/corp/Terms.htm" target="_blank" rel="noreferrer">
                Terms of Use
              </a>
            </Typography>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
