import { ReqStar } from 'components/form/ReqStar';

export const Required = ({ text }: { text: string }) => {
  return (
    <>
      {text}
      <ReqStar />
    </>
  );
};
