import { Box, Button, Grid, Stack } from '@mui/material';
import { Column } from 'primereact/column';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import {
  CapabilityType,
  RemoveCapabilityPayload,
  ResendUserInvitationPayload,
  User,
  UserSummary
} from 'shared/api/clients';
import { MuiIcon, RooIcon } from 'shared/icons';
import { useAppStore, useCurrentMemberships, useCurrentUser } from 'shared/store';
import { ConfirmationModal, ContentWrapper, NavSlim } from 'components';
import { showSuccess } from 'shared/utils';
import { useRequestGlobalModal } from 'components/modals';

export const ManagerList = () => {
  const [, toggleModal] = useRequestGlobalModal('inviteUser', {
    props: { capability: CapabilityType.PropertyManager }
  });
  const currentUser = useCurrentUser();

  const [{ isLoading, managers }, setState] = useState<{ isLoading: boolean; managers: User[] }>({
    isLoading: true,
    managers: null
  });

  const load = useCallback(async () => {
    setState({
      isLoading: true,
      managers: null
    });
    try {
      const managers = await apiProvider.usersClient.getManagers(currentUser.managementCompany.id);
      setState({
        isLoading: false,
        managers: managers
      });
    } catch (e) {}
  }, [currentUser.managementCompany.id]);

  useEffect(() => {
    void load();
  }, [load]);

  return (
    <>
      <Helmet>
        <title>PMs - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'PM List' }]} />
      <ContentWrapper className="container-property-list">
        <div
          className="d-flex justify-content-between align-items-center pt-3 pb-3 ps-4 pe-4"
          style={{ backgroundColor: 'white' }}
        >
          <Grid container className="align-items-center">
            <Grid item xs={5} md={3}>
              <h5 className="m-0">Property Managers</h5>
            </Grid>
            <Grid item xs={7} md={9}>
              <Box className="d-flex align-items-center justify-content-end">
                <Button size={'large'} color="primary" startIcon={<MuiIcon.Add />} onClick={toggleModal}>
                  Add PM
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
        <PrimeDataTable
          /* filters={filters as any} */ paginator
          loading={isLoading}
          rows={10}
          value={managers}
          dataKey={'id'}
        >
          <Column
            sortable
            filter
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            filterMatchMode={'contains'}
            field={'firstName'}
            header={'First Name'}
          />
          <Column
            filter
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            filterMatchMode={'contains'}
            sortable
            header={'Last Name'}
            field={'lastName'}
          />
          <Column
            filter
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            filterMatchMode={'contains'}
            sortable
            header={'Email'}
            field={'contactInfo.email'}
          />

          <Column
            header={'Actions'}
            body={(row: UserSummary) => <ActionButtons requestRefresh={() => load()} row={row} />}
            style={{ width: 210 }}
          />
        </PrimeDataTable>
      </ContentWrapper>
    </>
  );
};

const ActionButtons = ({ row, requestRefresh }: { row: UserSummary; requestRefresh: () => void }) => {
  const memberships = useCurrentMemberships();
  const isOwner = memberships.flatMap((x) => x.capabilities).indexOf(CapabilityType.ManagementCompanyOwner) >= 0;
  if (!isOwner) {
    return null;
  }
  return (
    <Stack direction={'row'} spacing={2}>
      <DeleteManagerButton requestRefresh={requestRefresh} targetUserId={row.id} />
      <ReinviteManagerButton user={row} />
    </Stack>
  );
};

const ReinviteManagerButton = ({ user }: { user: UserSummary }) => {
  const currentUser = useCurrentUser();
  const [isRunning, setIsRunning] = useState(false);
  if (user.isActive) {
    return null;
  }
  const reinvite = async () => {
    setIsRunning(true);
    try {
      await apiProvider.usersClient.resendUserInvitation(
        new ResendUserInvitationPayload({
          capabilityType: CapabilityType.PropertyManager,
          userId: user.id,
          managementCompanyId: currentUser.managementCompany.id
        })
      );
      showSuccess('Invitation sent successfully!');
    } catch (err) {}
    setIsRunning(false);
  };
  return (
    <Button color="primary" size="small" onClick={() => reinvite()}>
      {isRunning ? <RooIcon icon={['fas', 'circle-notch']} spin={isRunning} style={{ fontSize: 23 }} /> : 'Reinvite'}
    </Button>
  );
};

const DeleteManagerButton = ({
  targetUserId,
  requestRefresh
}: {
  targetUserId: string;
  requestRefresh: () => void;
}) => {
  const user = useAppStore((x) => x.userDetails);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const deleteManager = async () => {
    setIsRunning(true);
    try {
      await apiProvider.usersClient.removeCapability(
        new RemoveCapabilityPayload({
          capabilityType: CapabilityType.PropertyManager,
          managementCompanyId: user.managementCompany.id,
          userId: targetUserId
        })
      );
      requestRefresh();
      showSuccess();
    } catch (err) {}
    setIsRunning(false);
  };

  return (
    <>
      <Button color="error" size="small" onClick={() => setShowConfirmation(true)}>
        Delete
      </Button>
      <ConfirmationModal
        visible={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onSave={() => void deleteManager()}
        question={'Do you want to delete this PM?'}
        running={isRunning}
      />
    </>
  );
};
