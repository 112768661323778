import { useRef, useState } from 'react';
import { Button, Col, Modal, ModalFooter, Row } from 'react-bootstrap';
import { CSVReader } from 'react-papaparse';
import { apiProvider } from 'shared/api/apiProvider';
import {
  CreatePersonModel,
  CreatePropertyPayload,
  CreateTenantModel,
  ModifyAddressPayload,
  UpdatePropertyPayload
} from 'shared/api/clients';
import DownloadLink from 'shared/external/react-download-link';
import { RooIcon } from 'shared/icons';
import { redirect, Routes } from 'shared/routing';
import { useAppStore, useCurrentUser } from 'shared/store';
import { rooGeo, showSuccess, toSnakeCase } from 'shared/utils';
import { useWatchGlobalModal } from './modalStore';

export const PropertyUploadModal = () => {
  //TODO: move this into the props folder and convert to RooDialog whenever Ricky is done with it
  const { isVisible, toggle } = useWatchGlobalModal('propertyBulkImport');

  const properties = useAppStore((x) => x.properties);

  const onPropertyCreated = useAppStore((x) => x.actions.onPropertyCreated);

  const user = useCurrentUser();

  const [, setState] = useState<'pending' | 'disabled'>('disabled');

  const buttonRef = useRef(null);

  const handleOpenDialog = (e: any) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const onPropertyUpdated = useAppStore((x) => x.actions.onPropertyUpdated);

  const handleOnFileLoad = async (data: any) => {
    setState('pending');

    toggle();

    let headers: { data: any[] }[] = [];
    let rowsData: { data: any[] }[] = [];
    let headerRow = true;

    //TODO: rewrite
    //eslint-disable-next-line
    data?.filter((row: { data: any[] }, index: number) => {
      if (
        row.data.every((column: string, index: number) => {
          if (index === 0) {
            return true;
          } else {
            return column === '';
          }
        }) ||
        row.data.length <= 2
      ) {
        //Non header/data rows
      } else {
        if (headerRow) {
          headers.push(row);
          headerRow = false;
        } else {
          rowsData.push(row);
        }
      }
    });

    for (var i = 0; i < rowsData.length; i++) {
      let propertyObjectParsed: any = {};

      //eslint-disable-next-line
      rowsData[i].data.filter((columnData, columnIndex) => {
        propertyObjectParsed[toSnakeCase(headers[0].data[columnIndex])] = columnData;
      });
      const name = propertyObjectParsed['full_name']
        ? propertyObjectParsed['full_name'].split(' ')
        : propertyObjectParsed['tenant_full_name']
        ? propertyObjectParsed['tenant_full_name'].split(' ')
        : [];
      const firstName = name[0];
      let lastName = '';
      for (var k = 1; k < name.length; k++) {
        lastName += name[k];
        lastName += k === name.length - 1 ? '' : ' ';
      }
      const fullName = firstName + (lastName ? ' ' + lastName : '');

      let address1 = propertyObjectParsed['address']
        ? propertyObjectParsed['address']
        : propertyObjectParsed['address_1']
        ? propertyObjectParsed['address_1']
        : '';

      let address2 = propertyObjectParsed['address_cont.']
        ? propertyObjectParsed['address_cont.']
        : propertyObjectParsed['address_2']
        ? propertyObjectParsed['address_2']
        : '';

      if (!address2) {
        const address = address1.split(' ');
        address1 = '';
        address2 = '';
        let address2Part = false;
        let address1FirstIndex = true;
        let address2FirstIndex = true;
        for (const splittedAddress of address) {
          if (splittedAddress === 'Apt' || splittedAddress === 'Apt.' || address2Part) {
            address2Part = true;
            if (address2FirstIndex) {
              address2FirstIndex = false;
              address2 += splittedAddress;
            } else {
              address2 += ' ' + splittedAddress;
              address2 = address2.trim();
            }
          } else {
            if (address1FirstIndex) {
              address1FirstIndex = false;
              address1 += splittedAddress;
            } else {
              address1 += ' ' + splittedAddress;
              address1 = address1.trim();
            }
          }
        }
      }

      let state = propertyObjectParsed['state'] ? propertyObjectParsed['state'] : null;
      const zip = propertyObjectParsed['zip'] ? propertyObjectParsed['zip'] : '';

      if (!state && zip) {
        state = rooGeo.getStateByZipCode(zip.substring(0, 5));
      }

      const ownerFirstName: string = propertyObjectParsed['owner_first_name']
        ? propertyObjectParsed['owner_first_name']
        : '';
      const ownerLastName: string = propertyObjectParsed['owner_last_name']
        ? propertyObjectParsed['owner_last_name']
        : '';
      const ownerEmail: string = propertyObjectParsed['owner_email'] ? propertyObjectParsed['owner_email'] : '';
      const ownerPhone: string = propertyObjectParsed['owner_phone'] ? propertyObjectParsed['owner_phone'] : '';

      let propertyName = propertyObjectParsed['company']
        ? propertyObjectParsed['company']
        : propertyObjectParsed['property_name']
        ? propertyObjectParsed['property_name']
        : '';
      if (!propertyName) {
        propertyName = address1 + ' ' + address2;
      }

      const maintenanceLimit = propertyObjectParsed['maintenance_limit']
        ? propertyObjectParsed['maintenance_limit']
        : '';

      const city = propertyObjectParsed['city'] ? propertyObjectParsed['city'] : '';

      const email = propertyObjectParsed['email']
        ? propertyObjectParsed['email']
        : propertyObjectParsed['tenant_email']
        ? propertyObjectParsed['tenant_email']
        : '';
      const phone = propertyObjectParsed['mobile_phone_#']
        ? propertyObjectParsed['mobile_phone_#']
        : propertyObjectParsed['tenant_mobile_phone_#']
        ? propertyObjectParsed['tenant_mobile_phone_#']
        : '';

      if (
        propertyName !== '' ||
        maintenanceLimit !== '' ||
        address1 !== '' ||
        address2 !== '' ||
        city !== '' ||
        state !== '' ||
        zip !== '' ||
        ownerFirstName !== '' ||
        ownerLastName !== '' ||
        ownerEmail !== '' ||
        ownerPhone !== '' ||
        firstName !== '' ||
        lastName !== '' ||
        email !== '' ||
        phone !== ''
      ) {
        let propertyNotInserted = true;

        if (properties.length > 0) {
          let propertyNeedsUpdate = false;

          for (const property of properties) {
            propertyNeedsUpdate = false;

            if (
              (property.address.address1 ? property.address.address1.trim() : '') === address1 ||
              (property.address.address1 ? property.address.address1.trim() : '') +
                ' ' +
                (property.address.address2 ? property.address.address2.trim() : '') ===
                address1 + ' ' + address2
            ) {
              propertyNotInserted = false;
              if (
                (propertyName !== property.name && !property.name) ||
                (maintenanceLimit !== property.maintenanceLimit && !property.maintenanceLimit) ||
                (address1 !== property.address.address1.trim() && !property.address.address1) ||
                (address2 !== property.address.address2.trim() && !property.address.address2) ||
                (city !== property.address.city && !property.address.city) ||
                (state !== property.address.state && !property.address.state) ||
                (zip !== property.address.zipCode && !property.address.zipCode) ||
                (ownerFirstName !== property.owner.firstName && !property.owner.firstName) ||
                (ownerLastName !== property.owner.lastName && !property.owner.lastName) ||
                (ownerEmail !== property.owner.contactInfo.email && !property.owner.contactInfo.email) ||
                (ownerPhone !== property.owner.contactInfo.phoneNumber && !property.owner.contactInfo.phoneNumber)
              ) {
                propertyNeedsUpdate = true;
              } else {
                propertyNeedsUpdate = false;
              }

              let tenantNotInserted = true;
              let tenantNeedsUpdate = false;

              if (firstName !== '' || lastName !== '' || email !== '' || phone !== '') {
                if (property.tenants.length > 0) {
                  for (const tenant of property.tenants) {
                    if (
                      tenant.user.contactInfo.email === email ||
                      (tenant.user.contactInfo.phoneNumber ? tenant.user.contactInfo.phoneNumber : '')
                        .toString()
                        .replace(/\D/g, '')
                        .padStart(11, '1') === phone.toString().replace(/\D/g, '').padStart(11, '1')
                    ) {
                      tenantNotInserted = false;
                      if (
                        (firstName !== tenant.user.firstName && !tenant.user.firstName) ||
                        (lastName !== tenant.user.lastName && !tenant.user.lastName) ||
                        (fullName !== tenant.user.fullName && !tenant.user.fullName) ||
                        (phone.toString().replace(/\D/g, '').padStart(11, '1') !==
                          (tenant.user.contactInfo.phoneNumber ? tenant.user.contactInfo.phoneNumber : '')
                            .toString()
                            .replace(/\D/g, '')
                            .padStart(11, '1') &&
                          !tenant.user.contactInfo.phoneNumber)
                      ) {
                        tenantNeedsUpdate = true;

                        break;
                      } else {
                        tenantNeedsUpdate = false;
                      }
                    }
                  }
                }
              }

              if ((propertyNeedsUpdate && tenantNeedsUpdate) || tenantNeedsUpdate) {
                try {
                  const updated = await apiProvider.propertiesClient.update(
                    new UpdatePropertyPayload({
                      id: property.id,
                      name: propertyName,
                      maintenanceLimit: maintenanceLimit ? maintenanceLimit : property.maintenanceLimit,
                      isOccupied: property.isOccupied,
                      address: new ModifyAddressPayload({
                        address1: address1,
                        address2: address2,
                        city: city,
                        state: state,
                        zipCode: zip
                      }),
                      owner:
                        ownerFirstName && ownerEmail
                          ? new CreatePersonModel({
                              firstName: ownerFirstName ? ownerFirstName : property.owner.firstName,
                              lastName: ownerLastName ? ownerLastName : property.owner.lastName,
                              email: ownerEmail ? ownerEmail : property.owner.contactInfo.email,
                              phone: ownerPhone ? ownerPhone : property.owner.contactInfo.phoneNumber,
                              userId: property.owner.id
                            })
                          : property.owner != null
                          ? new CreatePersonModel({
                              email: property.owner.contactInfo.email,
                              userId: property.owner.id,
                              lastName: property.owner.lastName,
                              phone: property.owner.contactInfo.phoneNumber,
                              firstName: property.owner.firstName
                            })
                          : null,
                      bathroomNumber: property.bathroomNumber,
                      bedroomNumber: property.bedroomNumber,
                      gateCode: property.gateCode,
                      hasPool: property.hasPool,
                      lotSize: property.lotSize,
                      propertyArea: property.propertyArea,
                      managementCompanyId: user.managementCompany.id,
                      managerId: user.id,
                      tenants: property.tenants.map(
                        (x) =>
                          new CreateTenantModel({
                            firstName:
                              x.user.contactInfo.email === email ||
                              (x.user.contactInfo.phoneNumber ? x.user.contactInfo.phoneNumber : '')
                                .toString()
                                .replace(/\D/g, '')
                                .padStart(11, '1') === phone.toString().replace(/\D/g, '').padStart(11, '1')
                                ? firstName
                                : x.user.firstName,
                            lastName:
                              x.user.contactInfo.email === email ||
                              (x.user.contactInfo.phoneNumber ? x.user.contactInfo.phoneNumber : '')
                                .toString()
                                .replace(/\D/g, '')
                                .padStart(11, '1') === phone.toString().replace(/\D/g, '').padStart(11, '1')
                                ? lastName
                                : x.user.lastName,
                            phone: x.user.contactInfo.email === email ? phone : x.user.contactInfo.phoneNumber,
                            email: x.user.contactInfo.email,
                            userId: x.user.id,
                            moveInDate: x.moveInDate,
                            moveOutDate: x.moveOutDate
                          })
                      )
                    })
                  );
                  onPropertyUpdated(updated);
                } catch (err) {
                  console.error('err: ', err);
                }
              } else if (propertyNeedsUpdate) {
                try {
                  const updated = await apiProvider.propertiesClient.update(
                    new UpdatePropertyPayload({
                      id: property.id,
                      name: propertyName,
                      maintenanceLimit: maintenanceLimit ? maintenanceLimit : property.maintenanceLimit,
                      isOccupied: property.isOccupied,
                      address: new ModifyAddressPayload({
                        address1: address1,
                        address2: address2,
                        city: city,
                        state: state,
                        zipCode: zip
                      }),
                      owner:
                        ownerFirstName && ownerEmail
                          ? new CreatePersonModel({
                              firstName: ownerFirstName ? ownerFirstName : property.owner.firstName,
                              lastName: ownerLastName ? ownerLastName : property.owner.lastName,
                              email: ownerEmail ? ownerEmail : property.owner.contactInfo.email,
                              phone: ownerPhone ? ownerPhone : property.owner.contactInfo.phoneNumber,
                              userId: property.owner.id
                            })
                          : property.owner != null
                          ? new CreatePersonModel({
                              email: property.owner.contactInfo.email,
                              userId: property.owner.id,
                              lastName: property.owner.lastName,
                              phone: property.owner.contactInfo.phoneNumber,
                              firstName: property.owner.firstName
                            })
                          : null,
                      bathroomNumber: property.bathroomNumber,
                      bedroomNumber: property.bedroomNumber,
                      gateCode: property.gateCode,
                      hasPool: property.hasPool,
                      lotSize: property.lotSize,
                      propertyArea: property.propertyArea,
                      managementCompanyId: user.managementCompany.id,
                      managerId: user.id,
                      tenants: property.tenants.map(
                        (x) =>
                          new CreateTenantModel({
                            firstName: x.user.firstName,
                            lastName: x.user.lastName,
                            phone: x.user.contactInfo.phoneNumber,
                            email: x.user.contactInfo.email,
                            userId: x.user.id,
                            moveInDate: x.moveInDate,
                            moveOutDate: x.moveOutDate
                          })
                      )
                    })
                  );
                  onPropertyUpdated(updated);
                } catch (err) {
                  console.error('err: ', err);
                }
              } else if (tenantNotInserted) {
                if (email) {
                  try {
                    const updated = await apiProvider.propertiesClient.update(
                      new UpdatePropertyPayload({
                        id: property.id,
                        name: propertyName,
                        maintenanceLimit: maintenanceLimit ? maintenanceLimit : property.maintenanceLimit,
                        isOccupied: property.isOccupied,
                        address: new ModifyAddressPayload({
                          address1: address1,
                          address2: address2,
                          city: city,
                          state: state,
                          zipCode: zip
                        }),
                        owner:
                          ownerFirstName && ownerEmail
                            ? new CreatePersonModel({
                                firstName: ownerFirstName ? ownerFirstName : property.owner.firstName,
                                lastName: ownerLastName ? ownerLastName : property.owner.lastName,
                                email: ownerEmail ? ownerEmail : property.owner.contactInfo.email,
                                phone: ownerPhone ? ownerPhone : property.owner.contactInfo.phoneNumber,
                                userId: property.owner.id
                              })
                            : property.owner != null
                            ? new CreatePersonModel({
                                email: property.owner.contactInfo.email,
                                userId: property.owner.id,
                                lastName: property.owner.lastName,
                                phone: property.owner.contactInfo.phoneNumber,
                                firstName: property.owner.firstName
                              })
                            : null,
                        bathroomNumber: property.bathroomNumber,
                        bedroomNumber: property.bedroomNumber,
                        gateCode: property.gateCode,
                        hasPool: property.hasPool,
                        lotSize: property.lotSize,
                        propertyArea: property.propertyArea,
                        managementCompanyId: user.managementCompany.id,
                        managerId: user.id,
                        tenants: property.tenants
                          .map(
                            (x) =>
                              new CreateTenantModel({
                                firstName: x.user.firstName,
                                lastName: x.user.lastName,
                                phone: x.user.contactInfo.phoneNumber,
                                email: x.user.contactInfo.email,
                                userId: x.user.id,
                                moveInDate: x.moveInDate,
                                moveOutDate: x.moveOutDate
                              })
                          )
                          .concat(
                            new Array(
                              new CreateTenantModel({
                                firstName: firstName,
                                lastName: lastName,
                                email: email,
                                phone: phone,
                                userId: null,
                                moveInDate: null,
                                moveOutDate: null
                              })
                            )
                          )
                      })
                    );
                    onPropertyUpdated(updated);
                  } catch (err) {
                    console.error('err: ', err);
                  }
                }
              }
            }
          }
        }

        if (propertyNotInserted) {
          try {
            const newProp = await apiProvider.propertiesClient.create(
              new CreatePropertyPayload({
                name: propertyName,
                maintenanceLimit: maintenanceLimit,

                isOccupied: true,
                address: new ModifyAddressPayload({
                  address1: address1,
                  address2: address2,
                  city: city,
                  state: state,
                  zipCode: zip
                }),
                owner:
                  ownerFirstName && ownerEmail
                    ? new CreatePersonModel({
                        firstName: ownerFirstName,
                        lastName: ownerLastName,
                        email: ownerEmail,
                        phone: ownerPhone,
                        userId: null
                      })
                    : null,
                gateCode: '',
                hasPool: false,
                managementCompanyId: user.managementCompany.id,
                managerId: user.id,
                bedroomNumber: null,
                bathroomNumber: null,
                lotSize: null,
                propertyArea: null,
                tenants: email
                  ? [
                      new CreateTenantModel({
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phone: phone,
                        userId: null,
                        moveOutDate: null,
                        moveInDate: null
                      })
                    ]
                  : []
              })
            );
            onPropertyCreated(newProp.property);
          } catch (err) {
            console.error('err: ', err);
          }
        }
      }
    }

    redirect({ pathname: Routes.PropertyList });
    showSuccess();
    setState('disabled');
  };

  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    console.log('-------handleOnError-------');
    console.log('---------------------------');
    console.log(err);
    console.log('---------------------------');
  };

  const handleOnRemoveFile = (data: any) => {
    console.log('----handleOnRemoveFile-----');
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  const txtTemplate =
    'Tenant Full Name\tProperty Name\tAddress 1\tAddress 2\tCity\tState\tZip\tTenant Email\tTenant Mobile Phone #\tMaintenance Limit\tOwner First Name\tOwner Last Name\tOwner Email\tOwner Phone';
  const csvTemplate =
    'Tenant Full Name,Property Name,Address 1,Address 2,City,State,Zip,Tenant Email,Tenant Mobile Phone #,Maintenance Limit,Owner First Name,Owner Last Name,Owner Email,Owner Phone';

  return (
    <Modal show={isVisible} onHide={toggle} className={'modal-dialog-centered'}>
      <Modal.Header closeButton>
        <>Upload Your File</>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xl={12}>
            <p>Instructions to upload</p>
          </Col>
          <Col xl={12}>
            <p>
              1. Your file must be uploaded in one of the following formats: comma delimited (.csv) or tab delimited
              (.txt)
              <ol>
                <li>Locate and open the file</li>
                <li>
                  Select “File” ={'>'} “Export” ={'>'} “Change File Type”
                </li>
                <li>Choose the correct file format: Comma delimited (CSV) or Tab delimited (TEXT)</li>
                <li>Save and upload the newly exported file.</li>
              </ol>
            </p>
          </Col>
          <Col xl={12}>
            <p>
              2. Check your file for the correct column headers. If your file's headers don't match the system's, your
              data will not be uploaded. Click either of the links to view or download our file templates with correct
              headers: (
              <DownloadLink
                label=".txt"
                filename="Properties_comma_delimited_template.txt"
                exportFile={() => txtTemplate}
              />
              ) or (
              <DownloadLink
                label=".csv"
                filename="Properties_comma_delimited_template.csv"
                exportFile={() => csvTemplate}
              />
              )
            </p>
          </Col>
          <Col xl={12}>
            <p>3. Click the "Upload Properties File" button below to select and upload your file.</p>
          </Col>
          <Col xl={12}>
            <p>
              4. Still need help? Send us your file, and we can upload it for you:
              <ol>
                <li>Back out to the main Properties page and click the "Send Us Your File" button</li>
                <li>Select and Upload your file, along with any additional notes or questions</li>
                <li>Someone from our team will reach out to you after reviewing your request</li>
              </ol>
            </p>
          </Col>
          <Col xl={6}>
            <div style={{ display: 'inline-block' }}>
              <CSVReader
                ref={buttonRef}
                onFileLoad={handleOnFileLoad}
                onError={handleOnError}
                noClick
                noDrag
                onRemoveFile={handleOnRemoveFile}
                accept={'text/csv, .csv, text/plain'}
              >
                {({ file }: { file: any }) => (
                  <>
                    <Button className="btn-custom" onClick={handleOpenDialog}>
                      <RooIcon icon={['fas', 'building']} /> <b>Upload Properties File</b>
                    </Button>
                  </>
                )}
              </CSVReader>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <ModalFooter>
        <Button variant={'secondary'} onClick={toggle}>
          <b>Cancel</b>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
