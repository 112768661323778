import { CardContent, CardHeader } from '@mui/material';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import {
  AttachmentEntityType,
  CapabilityType,
  FileVisibility,
  Property,
  RooPermission,
  SetPropertyCoverImagePayload
} from 'shared/api/clients';
import { FileList } from 'components/Files/FileList';
import { useWorkordersGrid, WorkordersGrid } from 'components/Issues/WorkordersGrid';
import { RooIcon } from 'shared/icons';
import { redirect, Routes } from 'shared/routing';
import { useCurrentUser, useHasCapability, useHasPermission, useIsGeneralContractor } from 'shared/store';
import { MessageBig, NavSlim, PageContentSection, RouterLink, UserCard, UserLink, WithPlaceholder } from 'components';
import { rooFmt } from 'shared/utils';
import { useGetPropertyQuery } from '../../shared/api/queries';
import { useParams } from 'react-router';
import { CenteredLoader } from '../../components/CenteredLoader';
import { EditableImageThumbnail } from '../../components/Files/EditableImageThumbnail';
import { apiProvider } from '../../shared/api/apiProvider';
import { useQueryClient } from '@tanstack/react-query';

export const PropertyView = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, data } = useGetPropertyQuery(id);
  const propertyName = data?.name ?? 'Property';

  return (
    <>
      <Helmet>
        <title>{`${propertyName} - Properties - Walkthroo`}</title>
      </Helmet>

      <NavSlim breadcrumbs={[{ text: 'Property List', destination: Routes.PropertyList }, { text: propertyName }]} />
      <PageContentSection>
        {!isLoading && data != null && <PropertyDisplay property={data} />}
        {!isLoading && data == null && (
          <MessageBig message={"This property does not exist or you're not allowed to view it"} />
        )}
        {isLoading && <CenteredLoader />}
      </PageContentSection>
    </>
  );
};

const PropertyDisplay = ({ property }: { property: Property }) => {
  const currentUser = useCurrentUser();
  const isGc = useIsGeneralContractor();
  const isPropertyManager =
    useHasCapability(CapabilityType.PropertyManager, property.managementCompany?.id) ||
    property.createdBy?.id === currentUser.id ||
    isGc;

  const qc = useQueryClient();
  const isPropertyOwner = useHasCapability(CapabilityType.PropertyOwner, property.managementCompany.id);
  const isTenant = useHasCapability(CapabilityType.Tenant, property.managementCompany.id);
  const woGrid = useWorkordersGrid({
    forceExpanded: false,
    externalFilters: {
      forPropertyId: property.id
    }
  });

  const canAddEditProperties = useHasPermission(RooPermission.AddEditProperties, currentUser.managementCompany?.id);
  const canTakeActionWorkorder = useHasPermission(RooPermission.TakeActionWorkorder, currentUser.managementCompany?.id);
  return (
    <Row>
      <Col xl={3}>
        <Card>
          <Card.Header>
            <strong>Property</strong>
            {canAddEditProperties && (
              <button
                onClick={() => {
                  redirect({ pathname: Routes.PropertyEdit, params: { id: property.id } });
                }}
                className={'btn btn-primary btn-sm float-end slim'}
              >
                <RooIcon icon={['fas', 'pencil-alt']} /> Edit
              </button>
            )}
          </Card.Header>
          <Card.Body>
            <p className={'mb-0'}>
              <h4 className={'mb-0'}>{property.name}</h4>
            </p>
            <div className={'mb-2 mt-2'}>
              <EditableImageThumbnail
                currentFile={property.coverPhoto}
                disableEditing={!isPropertyManager}
                uploadMeta={{
                  entityType: AttachmentEntityType.Property,
                  entityId: property.id,
                  visibility: FileVisibility.Private
                }}
                saveFn={async (file) => {
                  await apiProvider.propertiesClient.setCoverImage(
                    new SetPropertyCoverImagePayload({ propertyId: property.id, fileId: file?.id })
                  );
                  await qc.invalidateQueries(['properties']);
                }}
              />
            </div>
            <p className={'mb-0'}>
              <h4 className={'mb-0'}>{property.isOccupied}</h4>
            </p>
            <div className={'d-flex'}>
              <p className={'mb-0'} style={{ marginRight: '5px' }}>
                <Badge bg={property.isOccupied ? 'primary' : 'success'}>
                  {property.isOccupied ? 'Occupied' : 'Not Occupied'}
                </Badge>
              </p>
              {!isTenant && (
                <p className={'mb-0'} style={{ marginRight: '5px' }}>
                  <Badge bg={'secondary'}>{rooFmt.money(property.maintenanceLimit)}</Badge>
                </p>
              )}
            </div>

            <dl>
              <dt>Address 1</dt>
              <dd>{property.address.address1}</dd>
              <dt>Address 2</dt>
              <dd>
                <WithPlaceholder value={property.address.address2} />
              </dd>
              <dt>City</dt>
              <dd>{property.address.city}</dd>
              <dt>State</dt>
              <dd>{property.address.state}</dd>
              <dt>ZIP Code</dt>
              <dd>{property.address.zipCode}</dd>
            </dl>
            {property.manager && (
              <>
                <div className={'mt-2'}>
                  <strong>Property Manager</strong>
                </div>
                <UserCard className={'mt-2 mb-2'} user={property.manager} role={'Property Manager'} />
              </>
            )}
            {property.owner && (isPropertyManager || isPropertyOwner) && (
              <>
                <div className={'mt-2'}>
                  <strong>Owner</strong>
                </div>
                <UserCard className={'mt-2 mb-2'} user={property.owner} role={'Owner'} />
              </>
            )}

            {(isPropertyManager || isPropertyOwner) && (
              <dl>
                <dt>Created</dt>
                <dd>
                  {rooFmt.date(property.createdAt)} by{' '}
                  {property.createdBy ? <UserLink user={property.createdBy} role={null} /> : <>Walkthroo</>}
                </dd>
              </dl>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Col xl={9}>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'flex-end', padding: '1em' }}>
            {canTakeActionWorkorder && (
              <RouterLink
                className="btn btn-primary"
                role="button"
                to={{ pathname: Routes.WorkorderCreate, state: { property } }}
              >
                + Add Workorder
              </RouterLink>
            )}
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <Card className={'mb-4'}>
              <Card.Header>Details</Card.Header>
              <Card.Body>
                <Row>
                  <Col xl={3}>
                    <dl>
                      <dd>Gate Code</dd>
                      <dt>
                        <WithPlaceholder value={property.gateCode} />
                      </dt>
                    </dl>
                  </Col>
                  <Col xl={3}>
                    <dl>
                      <dd>Bedroom No.</dd>
                      <dt>
                        <WithPlaceholder value={property.bedroomNumber} />
                      </dt>
                    </dl>
                  </Col>
                  <Col xl={3}>
                    <dl>
                      <dd>Bathroom No.</dd>
                      <dt>
                        <WithPlaceholder value={property.bathroomNumber} />
                      </dt>
                    </dl>
                  </Col>
                  <Col xl={3}>
                    <dl>
                      <dd>Property Area</dd>
                      <dt>
                        <WithPlaceholder value={property.propertyArea} />
                      </dt>
                    </dl>
                  </Col>
                  <Col xl={3}>
                    <dl>
                      <dd>Lot Size</dd>
                      <dt>
                        <WithPlaceholder value={property.lotSize} />
                      </dt>
                    </dl>
                  </Col>
                  <Col xl={3}>
                    <dl>
                      <dd>Has Pool</dd>
                      <dt>{property.hasPool ? 'Yes' : 'No'}</dt>
                    </dl>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={12} className={'mb-4'}>
            <FileList
              entityType={AttachmentEntityType.Property}
              entityId={property.id}
              allowUpload={true}
              forceAllowDelete={isPropertyManager}
              visibility={FileVisibility.Unknown}
            />
          </Col>
          <Col xl={12} className={'mb-4'}>
            <Card>
              <Card.Header>Tenants</Card.Header>
              <Card.Body>
                {!property.tenants.length && <h3 className={'mb-0 text-center'}>No tenants yet</h3>}

                {!!property.tenants.length && (
                  <div className={'d-flex flex-wrap'}>
                    {property.tenants.map((tenant) => (
                      <UserCard className={'m-2'} key={tenant.user.id} user={tenant.user} role={'Tenant'}>
                        {(isPropertyManager || isPropertyOwner) && (
                          <>
                            <dl className="row mb-0 mt-2">
                              <dd className="col-sm-6 mb-0">Move In</dd>{' '}
                              <dt className={'col-sm-6'}>{rooFmt.instantDateMaybe(tenant.moveInDate, 'N/A')}</dt>
                            </dl>
                            <dl className="row">
                              <dd className="col-sm-6 mb-0">Move Out</dd>{' '}
                              <dt className={'col-sm-6'}>{rooFmt.instantDateMaybe(tenant.moveOutDate, 'N/A')}</dt>
                            </dl>
                          </>
                        )}
                      </UserCard>
                    ))}
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xl={12}>
            <Card>
              <CardHeader title={'Workorders for Property'} />
              <CardContent>
                <WorkordersGrid state={woGrid} />
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
