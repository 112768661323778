import { Button, Form } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { IWorkorder, UpdateWorkorderPayload } from 'shared/api/clients';
import { useRooForm } from 'components/form/utils';
import { useAppStore } from 'shared/store';
import { SaveButton } from 'components';
import { RooDialog } from 'components/RooDialog';
import { showSuccess } from 'shared/utils';
import { WorkorderDefinition, WorkorderForm, WorkorderSchema } from 'pages/Workorders/shared/WorkorderForm';

export const EditWorkorderModal = ({
  workorder,
  isVisible,
  onCloseRequest,
  onSave
}: {
  workorder: IWorkorder;
  isVisible: boolean;
  onCloseRequest: () => void;
  onSave: (workorder: IWorkorder) => void;
}) => {
  const propertyGroups = useAppStore((x) => x.propertyGroups);
  const currentOption = propertyGroups.find((x) => x.children.find((y) => y.id === workorder.property.id) != null);
  const { handleSubmit, control, formState, watch } = useRooForm(WorkorderSchema, {
    defaultValues: {
      id: workorder.id,
      priority: workorder.priority,
      propertyGroupId: currentOption?.key,
      notes: workorder.notes,
      gateCode: workorder.gateCode,
      lockBox: workorder.lockBox,
      externalReference: workorder.externalReference,
      isEditing: true
    }
  });

  const submit = async (values: WorkorderDefinition) => {
    try {
      const newOrder = await apiProvider.workOrdersClient.update(
        new UpdateWorkorderPayload({
          id: values.id,
          notes: values.notes,
          priority: values.priority,
          gateCode: values.gateCode,
          lockBox: values.lockBox,
          externalReference: values.externalReference
        })
      );

      onSave(newOrder);
      onCloseRequest();
      showSuccess();
    } catch (err) {}
  };

  return (
    <RooDialog maxWidth={'sm'} fullWidth open={isVisible} onClose={onCloseRequest}>
      <Form noValidate style={{ marginBottom: 'initial' }} onSubmit={handleSubmit(submit)}>
        <RooDialog.Title onClose={onCloseRequest}>Edit Workorder</RooDialog.Title>
        <RooDialog.Content>
          <WorkorderForm watch={watch} control={control} isEditing={true} />
        </RooDialog.Content>
        <RooDialog.Actions>
          <SaveButton control={control}>Save</SaveButton>
          <Button
            disabled={formState.isSubmitting || formState.isValidating}
            variant={'secondary'}
            onClick={onCloseRequest}
          >
            Cancel
          </Button>
        </RooDialog.Actions>
      </Form>
    </RooDialog>
  );
};
