import { IInvoice, InvoiceStatus, IssueRole, ReviewInvoiceAction } from '../../../../../shared/api/clients';
import { ActionsMenu, ActionsMenuItem } from '../../../../../components/ActionsMenu';
import { RooButton } from '../../../../../components';
import { MuiIcon } from '../../../../../shared/icons';
import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import React from 'react';
import { ReviewInvoiceModal } from './ReviewInvoiceModal';
import { PayInvoiceModal } from './PayInvoiceModal';
import { PublishInvoiceModal } from './PublishInvoiceModal';
import { EditInvoiceModal } from './EditInvoiceModal';
import { useDisclosure } from '@roo/lib';
import { SplitInvoiceModal } from './SplitInvoiceModal';

export const InvoiceMenu = ({ invoice }: { invoice: IInvoice }) => {
  const actions = useAllowedActions(invoice);

  const payModal = useDisclosure(false);
  const acceptModal = useDisclosure(false);
  const rejectModal = useDisclosure(false);
  const publishModal = useDisclosure(false);
  const editModal = useDisclosure(false);
  const splitInvoiceModal = useDisclosure(false);

  if (actions.length === 0) {
    return null;
  }

  return (
    <>
      <ActionsMenu moreButton={(onClick) => <RooButton icon={'ellipsis-h'} onClick={onClick} />}>
        <ActionsMenuItem show={actions.includes('edit')} onClick={editModal.open} Icon={MuiIcon.Edit} color={'primary'}>
          Edit
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('publish')}
          onClick={publishModal.open}
          Icon={MuiIcon.Publish}
          color={'primary'}
        >
          Send to PM
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('accept')}
          onClick={acceptModal.open}
          Icon={MuiIcon.CheckCircle}
          color={'success'}
        >
          Accept
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('reject')}
          onClick={rejectModal.open}
          Icon={MuiIcon.Cancel}
          color={'error'}
        >
          Reject
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('pay')}
          onClick={payModal.open}
          Icon={MuiIcon.Payment}
          color={'primary'}
        >
          Pay
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('split')}
          onClick={splitInvoiceModal.open}
          Icon={MuiIcon.CallSplit}
          color={'primary'}
        >
          Split
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes('updateSplit')}
          onClick={splitInvoiceModal.open}
          Icon={MuiIcon.CallSplit}
          color={'primary'}
        >
          Update Split
        </ActionsMenuItem>
      </ActionsMenu>
      <ReviewInvoiceModal modal={acceptModal} invoice={invoice} action={ReviewInvoiceAction.Accept} />
      <ReviewInvoiceModal modal={rejectModal} invoice={invoice} action={ReviewInvoiceAction.Reject} />
      <PayInvoiceModal modal={payModal} invoice={invoice} />
      <PublishInvoiceModal modal={publishModal} invoice={invoice} />
      <EditInvoiceModal modal={editModal} invoice={invoice} />
      <SplitInvoiceModal invoiceId={invoice.id} modal={splitInvoiceModal} />
    </>
  );
};

type InvoiceAction = 'edit' | 'publish' | 'accept' | 'reject' | 'pay' | 'split' | 'updateSplit';

const useAllowedActions = (invoice: IInvoice) => {
  const { userRole } = useCurrentIssue();
  const actions: InvoiceAction[] = [];
  const status = invoice.status;
  const isManager = userRole === IssueRole.MainPropertyManager || userRole === IssueRole.AltPropertyManager;

  if (userRole === IssueRole.Vendor && status === InvoiceStatus.Draft) {
    actions.push('publish');
  }

  if (userRole === IssueRole.Vendor && (status === InvoiceStatus.Draft || status === InvoiceStatus.PendingReview)) {
    actions.push('edit');
  }

  if (isManager && status === InvoiceStatus.PendingReview) {
    actions.push('accept');
    actions.push('reject');
  }

  if (isManager && status === InvoiceStatus.Accepted) {
    actions.push('reject');
  }

  if (isManager && status !== InvoiceStatus.Draft && invoice.split == null) {
    actions.push('split');
  }

  if (isManager && status !== InvoiceStatus.Draft && invoice.split != null) {
    actions.push('updateSplit');
  }

  const isVendorOrTech = userRole === IssueRole.Vendor || userRole === IssueRole.Tech;
  if ((isManager || isVendorOrTech) && status === InvoiceStatus.Accepted) {
    actions.push('pay');
  }

  return actions;
};
