import { Grid, ListItemText, MenuItem, MenuList, Paper, styled } from '@mui/material';
import { RouteComponentProps } from 'react-router';
import { prepareRouterHrefAttrs, Routes } from '../../shared/routing';
import { Helmet } from 'react-helmet';
import { LoaderBig, NavSlim } from '../../components';
import { SectionContacts } from './SectionContacts';
import { useIsGeneralContractor } from '../../shared/store';
import { useCompanyById } from '../../shared/api/queries';
import { SectionIssues } from './SectionIssues';
import { SectionBasics } from './SectionBasics/SectionBasics';
import { WhiteBox } from '../../components/WhiteBox';
import Grid2 from '@mui/material/Unstable_Grid2';

type ProfileSections = 'basics' | 'contacts' | 'issues';

export const CompanyProfilePage = ({
  match: {
    params: { companyId, tab = 'basics' }
  }
}: RouteComponentProps<{ companyId: string; tab: ProfileSections }>) => {
  const isGc = useIsGeneralContractor();
  const { isLoading, data: company } = useCompanyById(companyId);

  if (isLoading) {
    return <LoaderBig />;
  }

  const makeTab = (newTab: ProfileSections) => {
    return {
      selected: tab === newTab,
      ...prepareRouterHrefAttrs({ pathname: Routes.CompanyProfile, params: { companyId: companyId, tab: newTab } })
    };
  };

  return (
    <>
      <Helmet>
        <title>{company.name} - Profile - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: company.name }]} />
      <Grid2 container p={1} spacing={1}>
        <Grid2 xs={12} md={4} lg={3}>
          <WhiteBox>
            <MenuList sx={{ pr: 1 }} className="menu-user-profile">
              <MenuItem sx={{ pt: 1, pb: 1 }} {...makeTab('basics')}>
                <ListItemText>Profile</ListItemText>
              </MenuItem>
              {isGc && (
                <MenuItem sx={{ pt: 1, pb: 1 }} {...makeTab('contacts')}>
                  <ListItemText>Contacts</ListItemText>
                </MenuItem>
              )}
              {isGc && (
                <MenuItem sx={{ pt: 1, pb: 1 }} {...makeTab('issues')}>
                  <ListItemText>Issues</ListItemText>
                </MenuItem>
              )}
            </MenuList>
          </WhiteBox>
        </Grid2>
        <Grid2 xs={12} md={8} lg={9}>
          {tab === 'basics' && (
            <WhiteBox sx={{ px: 4 }}>
              <SectionBasics company={company} />
            </WhiteBox>
          )}
          {isGc && tab === 'contacts' && <SectionContacts company={company} />}
          {isGc && tab === 'issues' && (
            <WhiteBox sx={{ px: 4 }}>
              <SectionIssues company={company} />
            </WhiteBox>
          )}
        </Grid2>
      </Grid2>
    </>
  );
};
